<template>
  <section id="sidebar">
    <div>
      <div v-bind="getRootProps()">
        <input v-bind="getInputProps()">
          <div class="avatar">
            <img :src=investor.avatar.image alt="">
            <span class='overlay'><i class="icon solid fa-camera"></i></span>
          </div>
      </div>
    </div>
  </section>
  <div id="main">
    <article class="post">
      <header>
        <div class="title">
          <h2>Investor Details</h2>
        </div>
        <div class="meta">
          <ul class="actions stacked fit right">
            <li v-if="!confirm">
              <a class="button fit icon solid fa-trash" @click.prevent="toggleConfirm">Delete</a>
            </li>
            <li v-if="confirm">
              <a class="button fit solid" @click.prevent="toggleConfirm">Cancel</a>
            </li>
          </ul>
          <p v-if="confirm">This investor will be removed from all properties.
            Any owned only by this investor will also be deleted.
            This cannot be undone.</p>
          <ul class="actions stacked fit right">
            <li v-if="confirm">
              <a class="button fit icon solid fa-trash" @click.prevent="deleteInvestor">Delete</a>
            </li>
          </ul>
        </div>
      </header>
      <form class="form" >
        <div class="row gtr-uniform">
          <div class="col-12">
            <input placeholder="Name" name="name" type="text"
              :value=investor.name @change.prevent=editInvestor />
          </div>
          <div class="col-12">
            <h3>Contact Details</h3>
          </div>
          <div class="col-12">
            <input type="text" name="phone" :value=investor.phone placeholder="Phone" @change.prevent=editInvestor />
          </div>
          <div class="col-12">
            <input type="text" name="email" :value=investor.email placeholder="Email" @change.prevent=editInvestor />
          </div>
          <div class="col-12">
            <input type="text" name="address" :value=investor.address placeholder="Address" @change.prevent=editInvestor />
          </div>
          <div class="col-6 col-12-xsmall">
            <input type="text" name="city" :value=investor.city placeholder="City" @change.prevent=editInvestor />
          </div>
          <div class="col-3 col-6-xsmall">
            <select name="state" :value=investor.state @change.prevent=editInvestor >
              <option value="VIC">VIC</option>
              <option value="NSW">NSW</option>
              <option value="WA">WA</option>
              <option value="QLD">QLD</option>
              <option value="TAS">TAS</option>
              <option value="SA">SA</option>
              <option value="ACT">ACT</option>
              <option value="NT">NT</option>
            </select>
          </div>
          <div class="col-3 col-6-xsmall">
            <input type="text" name="postcode" :value=investor.postcode
              placeholder="Postcode" @change.prevent=editInvestor />
          </div>
        </div>
      </form>
      <ul class="actions">
        <li>
          <router-link
            :class=uploading
            class="button fit icon solid fa-chevron-left"
            :to="{ name: 'InvestorView',
            params: { investorId: investor.id }}">
              Back
          </router-link>
        </li>
      </ul>
    </article>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useDropzone } from 'vue3-dropzone';

export default {
  name: 'InvestorEditView',
  setup() {
    const store = useStore();
    const route = useRoute();
    const saveFiles = (files) => {
      store.commit('uploading', true);
      store.dispatch('editAvatar', {
        files,
        id: route.params.investorId,
        type: 'investor',
      });
    };

    function onDrop(acceptFiles, rejectReasons) {
      saveFiles(acceptFiles);
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  data() {
    return {
      confirm: false,
    };
  },
  computed: {
    uploading() {
      return (this.$store.state.uploading ? 'disabled' : '');
    },
    investor() {
      return this.$store.getters.getInvestor(this.$route.params.investorId);
    },
  },
  methods: {
    editInvestor(event) {
      this.$store.dispatch('editInvestor', {
        name: event.target.name,
        value: event.target.value,
        id: this.investor.id,
      });
    },
    toggleConfirm() {
      this.confirm = !this.confirm;
    },
    deleteInvestor() {
      this.$store.dispatch('deleteInvestor', this.investor.id);
      this.$router.push({ name: 'HomeView' });
    },
  },
};
</script>
