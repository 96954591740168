<template>
  <div id="main">
    <article class="post">
      <header>
        <div class="title">
          <h2>Defaults - {{ user.name }}</h2>
          <p>Modify your defaults used when creating new investors and properties</p>
        </div>
      </header>
      <div id="rates-table" class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Rates</th>
              <th class="align-center" >Same as CPI?</th>
              <th class="align-center" >Default</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">Inflation (CPI)</td>
              <td>
                <input type="text" name="cpi" :value=parseFloat(defaults.cpi.rate).toFixed(2) @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
              </td>
            </tr>
            <tr>
              <td>Rental Income Growth</td>
              <td class="align-center" >
                <input type="checkbox" id="rentalIncome" name="rentalIncome"
                  v-model=defaults.rentalIncome.sameAsCpi @change.prevent="sameAsCpi($event)">
                <label for="rentalIncome"></label>
              </td>
              <td>
                <input :disabled=defaults.rentalIncome.sameAsCpi type="text" name="rentalIncome" :value="parseFloat(defaults.rentalIncome.sameAsCpi ? defaults.cpi.rate : defaults.rentalIncome.rate).toFixed(2)" @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
              </td>
            </tr>
            <tr>
              <td>Rental Expense Growth</td>
              <td class="align-center" >
                <input type="checkbox" id="rentalExpense" name="rentalExpense"
                  v-model=defaults.rentalExpense.sameAsCpi @change.prevent="sameAsCpi($event)">
                <label for="rentalExpense"></label>
              </td>
              <td>
                <input :disabled=defaults.rentalExpense.sameAsCpi type="text" name="rentalExpense" :value="parseFloat(defaults.rentalExpense.sameAsCpi ? defaults.cpi.rate : defaults.rentalExpense.rate).toFixed(2)" @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
              </td>
            </tr>
            <tr>
              <td>Personal Income Growth</td>
              <td class="align-center" >
                <input type="checkbox" id="taxableIncome" name="taxableIncome"
                  v-model=defaults.taxableIncome.sameAsCpi @change.prevent="sameAsCpi($event)">
                <label for="taxableIncome"></label>
              </td>
              <td>
                <input :disabled=defaults.taxableIncome.sameAsCpi type="text" name="taxableIncome" :value="parseFloat(defaults.taxableIncome.sameAsCpi ? defaults.cpi.rate : defaults.taxableIncome.rate).toFixed(2)" @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
              </td>
            </tr>
            <tr>
              <td colspan="2">Capital Growth</td>
              <td>
                <input type="text" name="capitalGrowth" :value=parseFloat(defaults.capitalGrowth.rate).toFixed(2) @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
              </td>
            </tr>
            <tr>
              <td colspan="2">Loan Interest</td>
              <td>
                <input type="text" name="loanInterest" :value=parseFloat(defaults.loanInterest.rate).toFixed(2) @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
              </td>
            </tr>
            <tr>
              <td colspan="2">Property Vacancy</td>
              <td>
                <input type="text" name="propertyVacancy" :value=parseFloat(defaults.propertyVacancy.rate).toFixed(2) @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
              </td>
            </tr>
            <tr>
              <td colspan="2">Rental Management</td>
              <td>
                <input type="text" name="rentalManagement" :value=parseFloat(defaults.rentalManagement.rate).toFixed(2) @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
              </td>
            </tr>
            <tr>
              <td colspan="2">Letting Fee</td>
              <td>
                <input type="text" name="lettingFee" :value=parseFloat(defaults.lettingFee.rate).toFixed(2) @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
              </td>
            </tr>
            <tr>
              <td colspan="2">Building Depreciation</td>
              <td>
                <input type="text" name="buildingDepreciation" :value=parseFloat(defaults.buildingDepreciation.rate).toFixed(2) @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="rates-table" class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Costs</th>
              <th class="align-center" >Default</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Conveyancing</td>
              <td>
                <input type="text" class="left" name="conveyancing" :value="parseInt(defaults.conveyancing.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
            <tr>
              <td>Other Purchase Costs</td>
              <td>
                <input type="text" class="left" name="otherPurchase" :value="parseInt(defaults.otherPurchase.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
            <tr>
              <td>Insurance</td>
              <td>
                <input type="text" class="left" name="insurance" :value="parseInt(defaults.insurance.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
            <tr>
              <td>Maintenance</td>
              <td>
                <input type="text" class="left" name="maintenance" :value="parseInt(defaults.maintenance.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
            <tr>
              <td>Council/Owner's Corporation Rates</td>
              <td>
                <input type="text" class="left" name="rates" :value="parseInt(defaults.rates.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
            <tr>
              <td>Establishment Fee</td>
              <td>
                <input type="text" class="left" name="establishment" :value="parseInt(defaults.establishment.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
            <tr>
              <td>Valuation Fees</td>
              <td>
                <input type="text" class="left" name="valuation" :value="parseInt(defaults.valuation.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
            <tr>
              <td>Registration Fees</td>
              <td>
                <input type="text" class="left" name="registration" :value="parseInt(defaults.registration.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
            <tr>
              <td>Solicitor's Fees</td>
              <td>
                <input type="text" class="left" name="solicitors" :value="parseInt(defaults.solicitors.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
            <tr>
              <td>Search Fees</td>
              <td>
                <input type="text" class="left" name="search" :value="parseInt(defaults.search.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="rates-table" class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Other Values</th>
              <th class="align-center" >Default</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Fittings</td>
              <td>
                <input type="text" class="left" name="fittings" :value="parseInt(defaults.fittings.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" /> <i class="icon solid fa-dollar-sign input left" />
              </td>
            </tr>
            <tr>
              <td>Loan Write-Off (Years)</td>
              <td>
                <input type="text" name="loanWriteOff" :value="parseInt(defaults.loanWriteOff.value, 10).toLocaleString()" @change.prevent="dollarChange($event)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'DefaultsView',
  computed: {
    defaults() {
      return this.$store.state.user.defaults;
    },
    user() {
      return this.$store.state.user;
    },
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    sameAsCpi(event) {
      this.$store.dispatch('defaultSameAsCpi', {
        name: event.target.name,
        value: event.target.checked,
      });
    },
    rateChange(event) {
      this.$store.dispatch('defaultRateChange', {
        name: event.target.name,
        value: event.target.value,
      });
    },
    dollarChange(event) {
      this.$store.dispatch('defaultDollarChange', {
        name: event.target.name,
        value: event.target.value,
      });
    },
  },
};
</script>
