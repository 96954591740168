<template>
  <div id="wrapper" :style=colours >
    <Header />
    <div class="loading" v-if=init_loading >
      <i class="icon solid fa-sync-alt"></i>
      <h4>Loading</h4>
    </div>
    <router-view v-else />
    <About />
    <div v-if=!!saving :class=saving class="panel toast">{{ saving[0].toUpperCase() + saving.substring(1) }}</div>
    <Footer />
  <Menu />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import About from '@/components/About.vue';
import Menu from '@/components/Menu.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    About,
    Menu,
  },
  created() {
    this.$store.dispatch('init_login');
  },
  computed: {
    colours() {
      return ({
        '--accent': this.$store.state.account.colour,
        '--bg-footer': this.$store.state.account.footerColour,
        '--text-footer': this.$store.state.account.footerTextColour,
        '--text-header': this.$store.state.account.headerTextColour,
        '--bg-page': this.$store.state.account.pageColour,
      });
    },
    init_loading() {
      return this.$store.state.init_loading;
    },
    saving() {
      return this.$store.state.saving;
    },
  },
};
</script>

<style lang="scss">

  @import '@/assets/sass/main.scss';

</style>
