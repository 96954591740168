import { createStore } from 'vuex';
import axios from 'axios';
import {
  auth,
  usersCollection,
  investorsCollection,
  propertiesCollection,
  accountsCollection,
  reportsCollection,
  mailCollection,
  resetCollection,
} from '@/firebase';
import * as formulajs from '@formulajs/formulajs';

import router from '../router';

const accountDefault = {
  colour: '#1b98a8',
  headerTextColour: '#FFFFFF',
  name: 'Augur Investor',
  footerColour: '#333333',
  footerTextColour: '#FFFFFF',
  pageColour: '#EEEEEE',
  disclaimer: 'The information provided is intended as general advice only and does not take into account individual needs or financial circumstances. Purchasers should complete their own assessment or consult a licensed investment advisor. The projections illustrate outcomes calculated from the values provided and the assumptions contained in the model. Figures can be varied as required but are in no way a guarantee of future performance. The information is provided in good faith on the basis that no person using the information, in whole or in part, shall have any claim against Augur Investor, their servants, employees or consultants.',
  id: null,
  subscription: 'personal',
  users: [],
};

const userDefault = {
  capitalGrowth: {
    rate: 6,
  },
  cpi: {
    rate: 3,
  },
  rentalExpense: {
    rate: 3,
    sameAsCpi: true,
  },
  rentalIncome: {
    rate: 3,
    sameAsCpi: true,
  },
  taxableIncome: {
    rate: 3,
    sameAsCpi: true,
  },
  loanInterest: {
    rate: 5,
  },
  propertyVacancy: {
    rate: 2,
  },
  rentalManagement: {
    rate: 8.25,
  },
  lettingFee: {
    rate: 2.2,
  },
  buildingDepreciation: {
    rate: 2.5,
  },
  loanWriteOff: {
    value: 5,
  },
  fittings: {
    value: 30000,
  },
  conveyancing: {
    value: 1500,
  },
  otherPurchase: {
    value: 0,
  },
  insurance: {
    value: 1000,
  },
  maintenance: {
    value: 2000,
  },
  rates: {
    value: 0,
  },
  oc: {
    value: 0,
  },
  establishment: {
    value: 700,
  },
  valuation: {
    value: 100,
  },
  registration: {
    value: 100,
  },
  solicitors: {
    value: 500,
  },
  search: {
    value: 100,
  },
};

const investorDefault = {
  id: null,
  name: null,
  avatar: {
    image: null,
    id: null,
  },
  address: null,
  city: null,
  state: null,
  postcode: null,
  phone: null,
  email: null,
  type: null,
  createDate: null,
  income: {
    amount: 0,
  },
  deduction: {
    amount: 0,
  },
  livingExpenses: {
    amount: 0,
  },
  residence: {
    amount: 0,
    initialLoan: {
      amount: 0,
    },
    interestRate: 0,
  },
};

const propertyDefault = {
  id: null,
  owners: [],
  avatar: {
    image: null,
    id: null,
  },
  address: null,
  city: null,
  state: 'VIC',
  postcode: null,
  description: null,
  bedrooms: 0,
  bathrooms: 0,
  carSpaces: 0,
  finance: {
    depreciation: {
      buildingRate: 2.5,
      fittings: 30000,
    },
    loan: {
      interestRate: 3.5,
      investment: 10000,
      writeOff: 5,
      type: 'io',
      repayment: 0,
    },
    loanCosts: {
      establishment: 700,
      insurance: 9000,
      registration: 100,
      solicitors: 500,
      valuation: 100,
      search: 100,
    },
    purchase: {
      type: 'existing',
      building: 100000,
      land: 100000,
      year: new Date().getFullYear(),
      entity: {
        type: 'Personal',
        name: '',
        income: '',
        taxRate: 'reduced',
      },
    },
    purchaseCosts: {
      stampDuty: 0,
      transferTitle: 0,
      conveyancingCosts: 1500,
      other: 0,
    },
    rentalIncome: {
      rent: 300,
      vacancyRate: 2,
    },
    rentalExpenses: {
      managementFee: {
        rate: 8.25,
        value: 0,
      },
      lettingFee: {
        rate: 2.2,
        value: 0,
      },
      rates: 0,
      oc: 0,
      insurance: 750,
      maintenance: 500,
      others: [],
    },
  },
};

export default createStore({
  state: {
    isLoggedIn: false,
    init_loading: false,
    account: {
      ...accountDefault,
    },
    user: {
      id: null,
      name: null,
      email: null,
      defaults: {},
    },
    aboutPanel: false,
    saving: null,
    uploading: false,
    menu: false,
    temporaryAvatar: null,
    investors: [],
    stampDuties: {
      vic: [
        {
          max: 25000,
          rate: 1.4,
          cost: 0,
          concession: 0,
        },
        {
          max: 130000,
          rate: 2.4,
          cost: 350,
          concession: 25000,
        },
        {
          max: 960000,
          rate: 6,
          cost: 2870,
          concession: 130000,
        },
        {
          max: 2000000,
          rate: 5.5,
          cost: 0,
          concession: 0,
        },
        {
          max: Infinity,
          rate: 6.5,
          cost: 110000,
          concession: 2000000,
        },
      ],
      qld: [
        {
          max: 5000,
          rate: 0,
          cost: 0,
          concession: 0,
        },
        {
          max: 75000,
          rate: 1.5,
          cost: 0,
          concession: 5000,
        },
        {
          max: 540000,
          rate: 3.5,
          cost: 1050,
          concession: 75000,
        },
        {
          max: 1000000,
          rate: 4.5,
          cost: 17325,
          concession: 540000,
        },
        {
          max: Infinity,
          rate: 5.75,
          cost: 38025,
          concession: 1000000,
        },
      ],
      wa: [
        {
          max: 120000,
          rate: 1.9,
          cost: 0,
          concession: 0,
        },
        {
          max: 150000,
          rate: 2.85,
          cost: 2280,
          concession: 120000,
        },
        {
          max: 360000,
          rate: 3.8,
          cost: 3135,
          concession: 150000,
        },
        {
          max: 725000,
          rate: 4.75,
          cost: 11115,
          concession: 360000,
        },
        {
          max: Infinity,
          rate: 5.15,
          cost: 28453,
          concession: 725000,
        },
      ],
      nt: [
        {
          max: 525000,
          rate: 0.06571441,
          cost: 15,
          concession: 0,
        },
        {
          max: 3000000,
          rate: 4.95,
          cost: 0,
          concession: 0,
        },
        {
          max: 5000000,
          rate: 5.75,
          cost: 0,
          concession: 0,
        },
        {
          max: Infinity,
          rate: 5.95,
          cost: 0,
          concession: 0,
        },
      ],
      nsw: [
        {
          max: 15000,
          rate: 1.25,
          cost: 0,
          concession: 0,
        },
        {
          max: 32000,
          rate: 1.5,
          cost: 187,
          concession: 15000,
        },
        {
          max: 87000,
          rate: 1.75,
          cost: 442,
          concession: 32000,
        },
        {
          max: 327000,
          rate: 3.5,
          cost: 1405,
          concession: 87000,
        },
        {
          max: 1089000,
          rate: 4.5,
          cost: 9805,
          concession: 327000,
        },
        {
          max: 3268000,
          rate: 5.5,
          cost: 44095,
          concession: 1089000,
        },
        {
          max: Infinity,
          rate: 7,
          cost: 163940,
          concession: 3268000,
        },
      ],
      sa: [
        {
          max: 12000,
          rate: 1,
          cost: 0,
          concession: 0,
        },
        {
          max: 30000,
          rate: 2,
          cost: 120,
          concession: 12000,
        },
        {
          max: 50000,
          rate: 3,
          cost: 480,
          concession: 30000,
        },
        {
          max: 100000,
          rate: 3.5,
          cost: 1080,
          concession: 50000,
        },
        {
          max: 200000,
          rate: 4,
          cost: 2830,
          concession: 50000,
        },
        {
          max: 250000,
          rate: 4.25,
          cost: 6830,
          concession: 200000,
        },
        {
          max: 300000,
          rate: 4.75,
          cost: 8955,
          concession: 250000,
        },
        {
          max: 500000,
          rate: 5,
          cost: 11330,
          concession: 300000,
        },
        {
          max: Infinity,
          rate: 5.5,
          cost: 21330,
          concession: 500000,
        },
      ],
      act: [
        {
          max: 200000,
          rate: 1.2,
          cost: 0,
          concession: 0,
        },
        {
          max: 300000,
          rate: 2.2,
          cost: 2400,
          concession: 200000,
        },
        {
          max: 500000,
          rate: 3.4,
          cost: 4600,
          concession: 300000,
        },
        {
          max: 750000,
          rate: 4.32,
          cost: 11400,
          concession: 500000,
        },
        {
          max: 1000000,
          rate: 5.9,
          cost: 22200,
          concession: 750000,
        },
        {
          max: 1455000,
          rate: 6.4,
          cost: 36950,
          concession: 1000000,
        },
        {
          max: Infinity,
          rate: 4.54,
          cost: 0,
          concession: 0,
        },
      ],
      tas: [
        {
          max: 3000,
          rate: 0,
          cost: 50,
          concession: 0,
        },
        {
          max: 25000,
          rate: 0.0175,
          cost: 50,
          concession: 3000,
        },
        {
          max: 75000,
          rate: 0.0225,
          cost: 435,
          concession: 25000,
        },
        {
          max: 200000,
          rate: 0.035,
          cost: 1560,
          concession: 75000,
        },
        {
          max: 375000,
          rate: 0.04,
          cost: 5935,
          concession: 200000,
        },
        {
          max: 725000,
          rate: 0.0425,
          cost: 12935,
          concession: 375000,
        },
        {
          max: Infinity,
          rate: 0.0450,
          cost: 27810,
          concession: 725000,
        },
      ],
    },
    taxRate: [
      {
        max: 18200,
        rate: 0,
        cost: 0,
        concession: 0,
      },
      {
        max: 45000,
        rate: 19,
        cost: 0,
        concession: 18200,
      },
      {
        max: 120000,
        rate: 32.5,
        cost: 5092,
        concession: 45000,
      },
      {
        max: 180000,
        rate: 37,
        cost: 29467,
        concession: 120000,
      },
      {
        max: Infinity,
        rate: 45,
        cost: 51667,
        concession: 180000,
      },
    ],
    transferTitle: {
      vic: {
        max: 3601,
        rate: 2.34,
        cost: 90.80,
      },
      qld: {
        concession: 180000,
        rate: 0.0037,
        cost: 197,
      },
      wa: {
        concession: 100000,
        rate: 0.0002,
        cost: 191.30,
      },
      nt: {
        concession: 0,
        rate: 0,
        cost: 156,
      },
      nsw: {
        concession: 0,
        rate: 0,
        cost: 141,
      },
      act: {
        concession: 0,
        rate: 0,
        cost: 409,
      },
      sa: {
        concession: 50000,
        rate: 0.00915,
        cost: 309,
      },
      tas: {
        concession: 0,
        rate: 0,
        cost: 213,
      },
    },
    properties: [],
    spreadsheet: {},
  },
  mutations: {
    spreadsheet(state, payload) {
      state.spreadsheet = payload;
    },
    setUser(state, payload) {
      state.user = { ...payload };
    },
    setAccount(state, payload) {
      state.account = {
        ...accountDefault,
        ...payload,
      };
    },
    setInvestors(state, payload) {
      state.investors = payload;
    },
    setProperties(state, payload) {
      state.properties = payload;
    },
    setUsers(state, users) {
      state.account.users = users.map((user) => ({
        access: 'standard',
        ...user,
      }));
    },
    setRates(state, {
      investor, type, date, rate, sameAsCpi,
    }) {
      const i = this.getters.getInvestor(investor);
      if (!i.rates[type]) {
        i.rates[type] = { rates: [] };
      }
      if (typeof sameAsCpi !== 'undefined') {
        i.rates[type].sameAsCpi = sameAsCpi;
      }
      i.rates[type].rates.push({ date, rate });
      state.investors = [
        ...state.investors.filter((element) => element.id !== investor),
        i,
      ];
    },
    clearState(state) {
      state.investors = [];
      state.properties = [];
      state.user = {};
      state.account = {
        colour: '#1b98a8',
        name: 'Augur Investor',
        id: null,
      };
    },
    toggleAuth(state) {
      state.isLoggedIn = !state.isLoggedIn;
    },
    isLoggedIn(state) {
      state.isLoggedIn = true;
    },
    toggleInitLoading(state) {
      state.init_loading = !state.init_loading;
    },
    about(state) {
      state.aboutPanel = !state.aboutPanel;
    },
    menu(state) {
      state.menu = !state.menu;
    },
    uploading(state, value) {
      state.uploading = value;
    },
    closeAbout(state) {
      state.aboutPanel = false;
    },
    closeMenu(state) {
      state.menu = false;
    },
    saving(state) {
      state.saving = 'saving';
    },
    sending(state) {
      state.saving = 'sending';
    },
    sent(state) {
      state.saving = 'sent';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    saved(state) {
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    temporaryAvatar(state, { value }) {
      state.temporaryAvatar = value;
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    clearTemporaryAvatar(state) {
      state.temporaryAvatar = null;
    },
    editUserName(state, name) {
      state.user.name = name;
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editUserEmail(state, email) {
      state.user.email = email;
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editInvestor(state, { name, value, id }) {
      const investor = this.getters.getInvestor(id);
      investor[name] = value;
      state.investors = [
        ...state.investors.filter((element) => element.id !== id),
        investor,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    deleteInvestor(state, id) {
      state.investors = [
        ...state.investors.filter((element) => element.id !== id),
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    deleteProperty(state, id) {
      state.properties = [
        ...state.properties.filter((element) => element.id !== id),
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    toggleAdmin(state, { id, status }) {
      state.account.users.forEach((element, index) => {
        if (element.id === id) {
          state.account.users[index].access = status;
        }
      });
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editAccount(state, { name, value }) {
      state.account[name] = value;
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editProperty(state, { name, value, id }) {
      const property = this.getters.getProperty(id);
      property[name] = value;
      state.properties = [
        ...state.properties.filter((element) => element.id !== id),
        property,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    addInvestor(state, investor) {
      state.investors.push({ ...investor });
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    addProperty(state, property) {
      state.properties.push({ ...property });
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    defaultRateChange(state, { name, value }) {
      state.user.defaults[name].rate = value;
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    defaultDollarChange(state, { name, value }) {
      state.user.defaults[name].value = value;
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    defaultSameAsCpi(state, { name, value }) {
      state.user.defaults[name].sameAsCpi = value;
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editIncome(state, { amount, id, date }) {
      const investor = this.getters.getInvestor(id);
      investor.income = { amount: parseInt(amount, 10), date };
      state.investors = [
        ...state.investors.filter((element) => element.id !== id),
        investor,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editDeduction(state, { amount, id, date }) {
      const investor = this.getters.getInvestor(id);
      investor.deduction = { amount: parseInt(amount, 10), date };
      state.investors = [
        ...state.investors.filter((element) => element.id !== id),
        investor,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editLivingExpenses(state, { amount, id, date }) {
      const investor = this.getters.getInvestor(id);
      investor.livingExpenses = { amount: parseInt(amount, 10), date };
      state.investors = [
        ...state.investors.filter((element) => element.id !== id),
        investor,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editResidence(state, {
      amount, type, id, date,
    }) {
      const investor = this.getters.getInvestor(id);
      if (type === 'initialLoan') {
        investor.residence[type].amount = parseInt(amount, 10);
        investor.residence[type].date = date;
      } else {
        investor.residence[type] = parseInt(amount, 10);
        investor.residence.date = date;
      }
      state.investors = [
        ...state.investors.filter((element) => element.id !== id),
        investor,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    investorSameAsCpi(state, { name, value, id }) {
      const investor = this.getters.getInvestor(id);
      investor.rates[name].sameAsCpi = value;
      state.investors = [
        ...state.investors.filter((element) => element.id !== id),
        investor,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    investorRateChange(state, {
      name, value, id,
    }) {
      const investor = this.getters.getInvestor(id);
      investor.rates[name].rate = value;
      state.investors = [
        ...state.investors.filter((element) => element.id !== id),
        investor,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editPurchaseCosts(state, {
      id, stampDuty, transferTitle, repayment, mortgageInsurance,
    }) {
      const property = this.getters.getProperty(id);
      if (property.finance.loan.repayment !== repayment.toString() || property.finance.purchaseCosts.stampDuty !== stampDuty.toString() || property.finance.purchaseCosts.transferTitle !== transferTitle.toString()) {
        property.finance.purchaseCosts.stampDuty = stampDuty.toString();
        property.finance.purchaseCosts.transferTitle = transferTitle.toString();
        property.finance.loan.repayment = repayment.toString();
        property.finance.loanCosts.insurance = mortgageInsurance.toString();
        state.properties = [
          ...state.properties.filter((element) => element.id !== id),
          property,
        ];
      }
    },
    editPropertyFinance(state, {
      name, value, id, type,
    }) {
      const property = this.getters.getProperty(id);
      property.finance[type][name] = value;
      state.properties = [
        ...state.properties.filter((element) => element.id !== id),
        property,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editEntity(state, {
      name, value, id,
    }) {
      const property = this.getters.getProperty(id);
      property.finance.purchase.entity[name] = value;
      state.properties = [
        ...state.properties.filter((element) => element.id !== id),
        property,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
    editRentalCosts(state, { id, name, value }) {
      const property = this.getters.getProperty(id);
      property.finance.rentalExpenses[name].rate = parseFloat(value);
      state.properties = [
        ...state.properties.filter((element) => element.id !== id),
        property,
      ];
      state.saving = 'saved';
      setTimeout(() => { state.saving = false; }, 2000);
    },
  },
  actions: {
    login({ dispatch, commit }, { email, password }) {
      return auth.signInWithEmailAndPassword(email, password)
        .then(() => dispatch('getUser'))
        .then((user) => {
          dispatch('getAccount', user.account);
          return dispatch('getInvestors', {
            id: auth.currentUser.uid,
            superAdmin: user.superAdmin,
          });
        })
        .then((ids) => dispatch('getProperties', ids))
        .then(() => {
          commit('isLoggedIn');
        })
        .catch((error) => {
          throw error;
        });
    },
    init_login({ dispatch, commit }) {
      commit('toggleInitLoading');
      const authUser = auth.currentUser;
      if (authUser) {
        dispatch('getUser')
          .then((user) => {
            console.log(user.superAdmin);
            dispatch('getAccount', user.account);
            return dispatch('getInvestors', {
              id: auth.currentUser.uid,
              superAdmin: user.superAdmin,
            });
          })
          .then((ids) => dispatch('getProperties', ids))
          .then(() => {
            commit('toggleAuth');
            commit('toggleInitLoading');
          });
      } else {
        commit('toggleInitLoading');
      }
    },
    getUser({ commit }) {
      return usersCollection.doc(auth.currentUser.uid).get()
        .then((user) => {
          const defaults = { ...userDefault, ...user.data().defaults };
          commit('setUser', {
            ...user.data(),
            defaults,
            id: auth.currentUser.uid,
            email: auth.currentUser.email,
          });
          return user.data();
        });
    },
    async getAccount({ commit }, id) {
      if (id) {
        return accountsCollection.doc(id).get()
          .then((account) => {
            if (account.data().subscription !== 'personal') {
              usersCollection.where(
                'account',
                '==',
                id,
              )
                .get()
                .then((results) => results.docs.map((result) => ({ ...result.data(), id: result.id })))
                .then((users) => {
                  commit('setUsers', users);
                });
            }
            commit('setAccount', { ...account.data(), id });
          });
      }
      return commit('setAccount', { ...accountDefault });
    },
    async editUserName({ commit }, { id, name }) {
      commit('saving');
      await usersCollection.doc(id).update({ name });
      commit('editUserName', name);
    },
    async resetPassword(context, { email }) {
      await resetCollection.add({ email });
      return 'sent';
    },
    async toggleAdmin({ commit }, { id, status }) {
      commit('saving');
      await usersCollection.doc(id).update({ access: status });
      commit('toggleAdmin', { id, status });
    },
    async editUserPassword({ commit }, { password }) {
      commit('saving');
      await auth.currentUser.updatePassword(password).then(() => {
        // Update successful.
        commit('saved');
      }).catch((error) => {
        // An error ocurred
        console.log(error);
      });
    },
    async editUserEmail({ commit }, { id, email }) {
      commit('saving');
      await usersCollection.doc(id).update({ email });
      await auth.currentUser.updateEmail(email).then(() => {
        // Update successful.
        commit('editUserEmail', email);
      }).catch((error) => {
        // An error ocurred
        console.log(error);
      });
    },
    async getProperties({ commit }, ids) {
      // don't run if there aren't any ids or a path for the collection
      if (!ids || !ids.length) return [];

      const batches = [];

      while (ids.length) {
        // firestore limits batches to 10
        const batch = ids.splice(0, 10);

        // add the batch request to to a queue
        batches.push(
          propertiesCollection
            .where(
              'owners',
              'array-contains-any',
              [...batch],
            )
            .get()
            .then((results) => results.docs.map((result) => ({ ...result.data(), id: result.id }))),
        );
      }

      // after all of the data is fetched, return it
      return Promise.all(batches)
        .then((properties) => commit('setProperties', properties.flat()));
    },
    getInvestors({ commit }, { id, superAdmin }) {
      const ids = [];
      const result = [];
      console.log(superAdmin);
      if (superAdmin) {
        return investorsCollection
          .get()
          .then((investors) => investors.forEach((investor) => {
            ids.push(investor.id);
            return result.push({ ...investor.data(), id: investor.id });
          }))
          .then(() => {
            commit('setInvestors', result);
            return ids;
          });
      }
      return investorsCollection
        .where('user', '==', id)
        .get()
        .then((investors) => investors.forEach((investor) => {
          ids.push(investor.id);
          return result.push({ ...investor.data(), id: investor.id });
        }))
        .then(() => {
          commit('setInvestors', result);
          return ids;
        });
    },
    async logout({ commit }) {
      await auth.signOut();
      commit('clearState');
      commit('toggleAuth');
      commit('closeAbout');
      commit('closeMenu');
    },
    addInvestor({ commit, state }, payload) {
      commit('saving');
      const i = {
        ...investorDefault,
        ...payload,
        rates: {
          ...state.user.defaults,
        },
        createDate: { seconds: Math.round((new Date()).getTime() / 1000) },
      };
      // const { rates, ...investor } = i;
      // return investorsCollection.add({ ...investor })
      return investorsCollection.add({ ...i })
        .then(({ id }) => {
          // if (rates) {
          //   Object.keys(rates).forEach((key) => {
          //     if (typeof rates[key].sameAsCpi !== 'undefined') {
          //       dispatch('investorSameAsCpi', { id, name: key, value: rates[key].sameAsCpi });
          //     }
          //     rates[key].rates.forEach(({ date, rate }) => {
          //       dispatch('investorRateChange', {
          //         id, name: key, value: rate, date,
          //       });
          //     });
          //   });
          // }
          commit('addInvestor', { ...investorDefault, ...i, id });
          router.push({ name: 'InvestorView', params: { investorId: id } });
        });
    },
    addProperty({ state, commit }, payload) {
      commit('saving');
      const property = {
        ...propertyDefault,
        ...payload,
        avatar: {
          ...propertyDefault.avatar,
          ...payload.avatar,
        },
        finance: {
          ...propertyDefault.finance,
          depreciation: {
            ...propertyDefault.finance.depreciation,
            buildingRate: state.user.defaults.buildingDepreciation.rate || userDefault.buildingDepreciation.rate,
            fittings: state.user.defaults.fittings.value || userDefault.fittings.value,
          },
          loan: {
            ...propertyDefault.finance.loan,
            interestRate: state.user.defaults.loanInterest.rate || userDefault.loanInterest.rate,
            writeOff: state.user.defaults.loanWriteOff.value || userDefault.loanWriteOff.value,

          },
          loanCosts: {
            ...propertyDefault.finance.loanCosts,
            establishment: state.user.defaults.establishment.value || userDefault.establishment.value,
            registration: state.user.defaults.registration.value || userDefault.registration.value,
            solicitors: state.user.defaults.solicitors.value || userDefault.solicitors.value,
            valuation: state.user.defaults.valuation.value || userDefault.valuation.value,
            search: state.user.defaults.search.value || userDefault.search.value,
          },
          purchase: {
            ...propertyDefault.finance.purchase,
            entity: {
              ...propertyDefault.finance.purchase.entity,
            },
          },
          purchaseCosts: {
            ...propertyDefault.finance.purchaseCosts,
            conveyancingCosts: state.user.defaults.conveyancing.value || userDefault.conveyancing.value,
            other: state.user.defaults.otherPurchase.value || userDefault.otherPurchase.value,
          },
          rentalIncome: {
            ...propertyDefault.finance.rentalIncome,
            vacancyRate: state.user.defaults.propertyVacancy.rate || userDefault.propertyVacancy.rate,
          },
          rentalExpenses: {
            ...propertyDefault.finance.rentalExpenses,
            managementFee: {
              ...propertyDefault.finance.rentalExpenses.managementFee,
              rate: state.user.defaults.rentalManagement.rate || userDefault.rentalManagement.rate,
            },
            lettingFee: {
              ...propertyDefault.finance.rentalExpenses.lettingFee,
              rate: state.user.defaults.lettingFee.rate || userDefault.lettingFee.rate,
            },
            rates: state.user.defaults.rates.value || userDefault.rates.value,
            oc: state.user.defaults.oc.value || userDefault.oc.value,
            insurance: state.user.defaults.insurance.value || userDefault.insurance.value,
            maintenance: state.user.defaults.maintenance.value || userDefault.maintenance.value,
          },
        },
        createDate: {
          seconds: Math.round((new Date()).getTime() / 1000),
        },
      };
      propertiesCollection.add(property).then((prop) => {
        commit('addProperty', { ...property, id: prop.id });
        router.push({ name: 'PropertyView', params: { propertyId: prop.id } });
      });
    },
    async editInvestor({ commit }, payload) {
      commit('saving');
      await investorsCollection.doc(payload.id).update({ [`${payload.name}`]: payload.value });
      commit('editInvestor', payload);
    },
    async deleteInvestor({ dispatch, commit }, id) {
      commit('saving');
      const properties = this.getters.properties(id);
      properties.forEach((property) => {
        const owners = [
          ...property.owners.filter((element) => element !== id),
        ];
        if (typeof property.ownership !== 'undefined') {
          const ownership = [
            ...property.ownership.filter((element) => element.key !== id),
          ];
          dispatch('editProperty', {
            id: property.id,
            name: 'ownership',
            value: ownership,
          });
        }
        dispatch('editProperty', {
          id: property.id,
          name: 'owners',
          value: owners,
        });
      });
      await investorsCollection.doc(id).delete();
      commit('deleteInvestor', id);
    },
    async deleteProperty({ commit }, id) {
      commit('saving');
      await propertiesCollection.doc(id).delete();
      commit('deleteProperty', id);
    },
    copyProperty({ commit }, payload) {
      const property = payload;
      commit('saving');
      delete property.id;
      propertiesCollection.add(property).then((prop) => {
        commit('addProperty', { ...property, id: prop.id });
        router.push({ name: 'PropertyView', params: { propertyId: prop.id } });
      });
    },
    async moveProperty({ dispatch, commit }, payload) {
      commit('saving');
      const owners = [payload.new];
      const ownership = [{
        investor: this.getters.getInvestor(payload.new),
        key: payload.new,
        percentage: 100,
      }];
      dispatch('editProperty', {
        id: payload.property,
        name: 'ownership',
        value: ownership,
      });
      dispatch('editProperty', {
        id: payload.property,
        name: 'owners',
        value: owners,
      });
      commit('moveProperty', payload);
      router.push({ name: 'PropertyView', params: { investorId: payload.new, propertyId: payload.property } });
    },
    async editAccount({ commit }, payload) {
      commit('saving');
      await accountsCollection.doc(payload.id).update({ [`${payload.name}`]: payload.value });
      commit('editAccount', payload);
    },
    async editProperty({ commit }, payload) {
      commit('saving');
      await propertiesCollection.doc(payload.id).update({ [`${payload.name}`]: payload.value });
      commit('editProperty', payload);
    },
    async defaultRateChange({ commit }, payload) {
      commit('saving');
      await usersCollection.doc(auth.currentUser.uid).update({ [`defaults.${payload.name}.rate`]: payload.value });
      commit('defaultRateChange', payload);
    },
    async defaultDollarChange({ commit }, payload) {
      commit('saving');
      await usersCollection.doc(auth.currentUser.uid).update({ [`defaults.${payload.name}.value`]: payload.value });
      commit('defaultDollarChange', payload);
    },
    async defaultSameAsCpi({ commit }, payload) {
      commit('saving');
      await usersCollection.doc(auth.currentUser.uid).update({ [`defaults.${payload.name}.sameAsCpi`]: payload.value });
      commit('defaultSameAsCpi', payload);
    },
    async investorSameAsCpi({ commit }, payload) {
      commit('saving');
      await investorsCollection
        .doc(payload.id).update({
          [`rates.${payload.name}.sameAsCpi`]: payload.value,
        });
      commit('investorSameAsCpi', payload);
    },
    async investorRateChange({ commit }, payload) {
      commit('saving');
      await investorsCollection
        .doc(payload.id).update({
          [`rates.${payload.name}.rate`]: parseFloat(payload.value),
        });
      commit('investorRateChange', payload);
    },
    temporaryAvatar({ commit }, payload) {
      commit('saving');
      const formData = new FormData();
      for (let x = 0; x < payload.files.length; x += 1) {
        formData.append('file[]', payload.files[x]);
        formData.append('upload_preset', payload.type);
      }
      const { VUE_APP_CLOUDINARY_UPLOAD_URL } = process.env;

      axios.post(VUE_APP_CLOUDINARY_UPLOAD_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          commit('temporaryAvatar', {
            value: { image: response.data.eager[0].secure_url, id: response.data.public_id },
          });
          commit('uploading', false);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    editAvatar({ commit, dispatch }, payload) {
      commit('saving');
      const formData = new FormData();
      for (let x = 0; x < payload.files.length; x += 1) {
        formData.append('file[]', payload.files[x]);
        formData.append('upload_preset', payload.type);
      }
      const { VUE_APP_CLOUDINARY_UPLOAD_URL } = process.env;

      axios.post(VUE_APP_CLOUDINARY_UPLOAD_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          if (payload.type === 'investor') {
            dispatch('editInvestor', {
              name: 'avatar',
              value: { image: response.data.eager[0].secure_url, id: response.data.public_id },
              id: payload.id,
            });
          } else {
            dispatch('editProperty', {
              name: 'avatar',
              value: { image: response.data.eager[0].secure_url, id: response.data.public_id },
              id: payload.id,
            });
          }
          commit('uploading', false);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    sendContactForm({ commit }, { name, email, message }) {
      commit('sending');
      const data = {
        to: 'support@augurinvestor.com.au',
        replyTo: email,
        message: {
          subject: 'Augur Contact Form',
          html: `<b>Name:</b> ${name}<br><b>Email:</b> ${email}<br><b>Message:</b> ${message}`,
        },
      };
      mailCollection.add(data).then(() => {
        commit('closeAbout');
        commit('sent');
      });
    },
    async editIncome({ commit }, payload) {
      commit('saving');
      const inv = { ...payload, date: new Date().getFullYear().toString() };
      await investorsCollection.doc(payload.id).update({ income: { amount: parseInt(inv.amount, 10), date: inv.date } });
      commit('editIncome', inv);
    },
    async editDeduction({ commit }, payload) {
      commit('saving');
      const inv = { ...payload, date: new Date().getFullYear().toString() };
      await investorsCollection.doc(payload.id).update({ deduction: { amount: parseInt(inv.amount, 10), date: inv.date } });
      commit('editDeduction', payload);
    },
    async editLivingExpenses({ commit }, payload) {
      commit('saving');
      const inv = { ...payload, date: new Date().getFullYear().toString() };
      await investorsCollection.doc(payload.id).update({ livingExpenses: { amount: parseInt(inv.amount, 10), date: inv.date } });
      commit('editLivingExpenses', payload);
    },
    async editResidence({ commit }, payload) {
      commit('saving');
      const inv = { ...payload, date: new Date().getFullYear().toString() };
      if (payload.type === 'initialLoan') {
        await investorsCollection.doc(payload.id).update({ 'residence.initialLoan': { amount: parseInt(inv.amount, 10), date: inv.date } });
      } else {
        await investorsCollection.doc(payload.id).update({ [`residence.${payload.type}`]: parseInt(inv.amount, 10), 'residence.date': inv.date });
      }
      commit('editResidence', payload);
    },
    editPropertyFinance({ commit }, payload) {
      commit('saving');
      return propertiesCollection.doc(payload.id).update({ [`finance.${payload.type}.${payload.name}`]: payload.value })
        .then(() => commit('editPropertyFinance', payload));
    },
    editEntity({ commit }, payload) {
      commit('saving');
      return propertiesCollection.doc(payload.id).update({ [`finance.purchase.entity.${payload.name}`]: payload.value })
        .then(() => commit('editEntity', payload));
    },
    editPurchaseCosts: ({ state, commit }, { property, value }) => {
      const prop = property;
      const val = value ?? 0;
      const allStampDuty = state.stampDuties[prop.state.toLowerCase()];
      const stateTransferTitle = state.transferTitle[prop.state.toLowerCase()];
      const stateStampDuty = allStampDuty.find(
        (i) => parseInt(val, 10) < i.max,
      );
      let stampDuty;
      if (prop.state.toLowerCase() === 'nt' && stateStampDuty.cost > 0) {
        stampDuty = parseInt(((value / 1000) ** 2 * (stateStampDuty.rate)) + (stateStampDuty.cost * (value / 1000)), 10);
      } else {
        stampDuty = parseInt(((value - stateStampDuty.concession) * (stateStampDuty.rate / 100)) + stateStampDuty.cost, 10);
      }
      let transferTitle;
      if (prop.state.toLowerCase() === 'vic') {
        transferTitle = parseInt(Math.ceil(Math.min(stateTransferTitle.max, ((Math.floor(value / 1000)) * stateTransferTitle.rate) + stateTransferTitle.cost)), 10);
      } else {
        transferTitle = stateTransferTitle.cost + parseInt((value - stateTransferTitle.concession) * stateTransferTitle.rate, 10);
      }
      const ipv = parseInt(prop.finance.purchase.land, 10) + parseInt(prop.finance.purchase.building, 10);
      let repayment;
      const ln = parseInt(ipv, 10) - parseInt(prop.finance.loan.investment, 10);
      if (prop.finance.loan.type === 'pi') {
        repayment = parseInt(formulajs.PMT(prop.finance.loan.interestRate / 100 / 12, 300, -ln, 0, 0), 10);
      } else {
        repayment = parseInt((ln * (parseFloat(prop.finance.loan.interestRate) / 100)) / 12, 10);
      }
      let mortgageInsurance;
      if (prop.finance.loan.investment / ipv < 0.2) {
        mortgageInsurance = (ipv - prop.finance.loan.investment) * 0.02;
      } else {
        mortgageInsurance = 0;
      }
      return propertiesCollection.doc(prop.id).update({
        'finance.purchaseCosts.stampDuty': stampDuty,
        'finance.purchaseCosts.transferTitle': transferTitle,
        'finance.loan.repayment': repayment,
        'finance.loanCosts.insurance': mortgageInsurance,
      })
        .then(() => commit('editPurchaseCosts', {
          id: prop.id,
          stampDuty,
          transferTitle,
          repayment,
          mortgageInsurance,
        }));
    },
    editRentalCosts: ({ commit }, payload) => {
      commit('saving');
      return propertiesCollection.doc(payload.id).update({
        [`finance.rentalExpenses.${payload.name}.rate`]: parseFloat(payload.value),
      })
        .then(() => commit('editRentalCosts', payload));
    },
    generateReport: ({ state }) => {
      try {
        return reportsCollection
          .add(state.spreadsheet)
          .then((doc) => doc.id);
      } catch (error) {
        console.error(error);
        return 'tmpAugur-1';
      }
    },
  },
  getters: {
    getInvestor: (state) => (id) => {
      if (state.isLoggedIn) {
        let investor;
        const inv = state.investors.find(
          (i) => i.id === id,
        );
        if (inv) {
          if (inv.avatar.image) {
            investor = { ...inv };
          } else {
            investor = { ...inv, avatar: { image: '/images/avatar.svg', id: null } };
          }
          return investor;
        }
        return null;
      }
      return null;
    },
    getProperty: (state) => (id) => {
      if (state.isLoggedIn) {
        const payload = state.properties.find(
          (property) => property.id === id,
        );
        const property = {
          ...propertyDefault,
          ...payload,
          avatar: {
            ...propertyDefault.avatar,
            ...payload.avatar,
          },
          finance: {
            ...propertyDefault.finance,
            ...payload.finance,
            depreciation: {
              ...propertyDefault.finance.depreciation,
              ...payload.finance.depreciation,
            },
            loan: {
              ...propertyDefault.finance.loan,
              ...payload.finance.loan,
            },
            loanCosts: {
              ...propertyDefault.finance.loanCosts,
              ...payload.finance.loanCosts,
            },
            purchase: {
              ...propertyDefault.finance.purchase,
              ...payload.finance.purchase,
              entity: {
                ...propertyDefault.finance.purchase.entity,
                ...payload.finance.purchase.entity,
              },
            },
            purchaseCosts: {
              ...propertyDefault.finance.purchaseCosts,
              ...payload.finance.purchaseCosts,
            },
            rentalIncome: {
              ...propertyDefault.finance.rentalIncome,
              ...payload.finance.rentalIncome,
            },
            rentalExpenses: {
              ...propertyDefault.finance.rentalExpenses,
              ...payload.finance.rentalExpenses,
              managementFee: {
                ...propertyDefault.finance.rentalExpenses.managementFee,
                ...payload.finance.rentalExpenses.managementFee,
              },
              lettingFee: {
                ...propertyDefault.finance.rentalExpenses.lettingFee,
                ...payload.finance.rentalExpenses.lettingFee,
              },
            },
          },
          createDate: {
            seconds: Math.round((new Date()).getTime() / 1000),
          },
        };
        if (!property.avatar.image) {
          property.avatar = { image: '/images/property.svg', id: null };
        }
        return { ...property };
      }
      return null;
    },
    investors(state) {
      const investors = state.investors.map(
        (inv) => {
          if (!inv.avatar.image) {
            return { ...inv, avatar: { image: '/images/avatar.svg', id: null } };
          }
          return { ...inv };
        },
      );
      return investors.sort((a, b) => new Date(b.createDate.seconds * 1000) - new Date(a.createDate.seconds * 1000));
    },
    properties: (state) => (id) => {
      let properties = state.properties.filter(
        (property) => property.owners.includes(id),
      );
      properties = properties.map(
        (payload) => {
          const property = {
            ...propertyDefault,
            ...payload,
            avatar: {
              ...propertyDefault.avatar,
              ...payload.avatar,
            },
            finance: {
              ...propertyDefault.finance,
              ...payload.finance,
              depreciation: {
                ...propertyDefault.finance.depreciation,
                ...payload.finance.depreciation,
              },
              loan: {
                ...propertyDefault.finance.loan,
                ...payload.finance.loan,
              },
              loanCosts: {
                ...propertyDefault.finance.loanCosts,
                ...payload.finance.loanCosts,
              },
              purchase: {
                ...propertyDefault.finance.purchase,
                ...payload.finance.purchase,
                entity: {
                  ...propertyDefault.finance.purchase.entity,
                  ...payload.finance.purchase.entity,
                },
              },
              purchaseCosts: {
                ...propertyDefault.finance.purchaseCosts,
                ...payload.finance.purchaseCosts,
              },
              rentalIncome: {
                ...propertyDefault.finance.rentalIncome,
                ...payload.finance.rentalIncome,
              },
              rentalExpenses: {
                ...propertyDefault.finance.rentalExpenses,
                ...payload.finance.rentalExpenses,
                managementFee: {
                  ...propertyDefault.finance.rentalExpenses.managementFee,
                  ...payload.finance.rentalExpenses.managementFee,
                },
                lettingFee: {
                  ...propertyDefault.finance.rentalExpenses.lettingFee,
                  ...payload.finance.rentalExpenses.lettingFee,
                },
              },
            },
            createDate: {
              seconds: Math.round((new Date()).getTime() / 1000),
            },
          };
          if (!property.avatar.image) {
            property.avatar = { image: '/images/property.svg', id: null };
          }
          return { ...property };
        },
      );
      return properties.sort((a, b) => new Date(b.finance.purchase.year) - new Date(a.finance.purchase.year));
    },
    getRate: (state) => (investor, type, date) => {
      let data = {};
      const r = investor.rates;
      if (r && Object.keys(r).length === 6) {
        const { rates } = r[type];
        const i = rates.find((rate) => parseInt(rate.date, 10) === parseInt(date, 10));
        if (i !== undefined && rates.sameAsCpi !== true) {
          data = i.rate;
        }
        if (investor.rates.cpi.length) {
          data = investor.rates.cpi.find((rate) => parseInt(rate.date, 10) === parseInt(date, 10)).rate;
        }
        data = state.user.defaults[type].rate;
      }
      return data;
    },
    getTax: (state) => (income) => {
      const taxRate = state.taxRate.find(
        (i) => parseInt(income, 10) < i.max,
      );
      const tax = parseInt(((income - taxRate.concession) * (taxRate.rate / 100)) + taxRate.cost, 10);
      return tax;
    },
    getFinance: (state, getters) => (investor) => {
      const date = new Date().getFullYear().toString();
      const inflation = (variance, rate, initial, inv) => {
        const r = getters.getRate(inv, rate, initial.date);
        return {
          ...initial,
          amount: parseInt(initial.amount, 10) * (1 + parseInt(r, 10) / 100) ** (parseInt(variance, 10)),
        };
      };
      const deduction = () => {
        const variance = date - investor.deduction.date;
        if (variance) {
          return inflation(variance, 'cpi', investor.deduction, investor);
        }
        return investor.deduction;
      };
      const income = () => {
        const variance = date - investor.income.date;
        if (variance) {
          return inflation(variance, 'taxableIncome', investor.income, investor);
        }
        return investor.income;
      };
      const livingExpenses = () => {
        const variance = date - investor.livingExpenses.date;
        if (variance) {
          return inflation(variance, 'livingExpense', investor.livingExpenses, investor);
        }
        return investor.livingExpenses;
      };
      const residence = () => {
        const variance = date - investor.residence.date;
        if (variance) {
          return { ...investor.residence, ...inflation(variance, 'capitalGrowth', investor.residence, investor) };
        }
        return investor.residence;
      };
      const finance = {
        income: income(),
        deduction: deduction(),
        livingExpenses: livingExpenses(),
        residence: residence(),
      };
      return (finance);
    },
  },
});
