<template>
  <section id="sidebar">
    <InvestorDetails />
  </section>
  <div id="main">
    <article v-if="finance.income.amount == 0" class="investor">
      <header>
        <div class="title">
          <h2>
            No Income Set
          </h2>
          <p>Ensure you add this investor's income before continuing.</p>
        </div>
      </header>
    </article>
    <Properties />
  </div>
</template>

<script>
import Properties from '@/components/Properties.vue';
import InvestorDetails from '@/components/InvestorDetails.vue';

export default {
  name: 'InvestorView',
  components: {
    Properties,
    InvestorDetails,
  },
  computed: {
    investor() {
      return this.$store.getters.getInvestor(this.$route.params.investorId);
    },
    finance() {
      return this.$store.getters.getFinance(this.investor);
    },
  },
};
</script>
