<template>
  <header id="header">
    <h1 class="heading" v-if="isLoggedIn && breadcrumbList.length > 1" ><router-link :to="{ name: 'HomeView'}" >{{ account.name }}</router-link></h1>
    <nav v-if="isLoggedIn && breadcrumbList.length > 1" id="back" class="main">
      <ul>
        <li class="menu">
          <router-link
            :to="{
              name: breadcrumbList[breadcrumbList.length - 2].link,
              params: breadcrumbList[breadcrumbList.length - 2].params }" class="fa-chevron-left" >
            Back
          </router-link>
        </li>
      </ul>
    </nav>
    <h1 v-else ><router-link :to="{ name: 'HomeView'}" >{{ account.name }}</router-link></h1>
    <nav v-if=isLoggedIn class="links">
      <ul>
        <li v-for="(breadcrumb, index) in breadcrumbList" :key=index >
          <template v-if=breadcrumb.link>
            <router-link
              :to="{
                name: breadcrumb.link,
                params: breadcrumb.params }">
              {{ breadcrumb.name }}
            </router-link>
          </template>
          <template v-else >
            <span>{{ breadcrumb.name }}</span>
          </template>
        </li>
      </ul>
    </nav>
    <nav v-if=isLoggedIn class="main">
      <ul>
        <!-- <li class="search">
          <a class="fa-search" href="#search">Search</a>
          <form id="search" method="get" action="#">
            <input type="text" name="query" placeholder="Search" />
          </form>
        </li> -->
        <li class="menu">
          <a class="fa-bars" @click="menu">Menu</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      breadcrumbList: [],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    investor() {
      if (this.$route.params.investorId) {
        return this.$store.getters.getInvestor(this.$route.params.investorId);
      }
      return null;
    },
    property() {
      if (this.$route.params.propertyId) {
        return this.$store.getters.getProperty(this.$route.params.propertyId);
      }
      return null;
    },
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    menu() {
      this.$store.commit('menu');
    },
    breadcrumbs() {
      if (this.isLoggedIn) {
        const breadcrumbs = this.$route.meta.breadcrumb.map((breadcrumb) => {
          if (breadcrumb.investor) {
            return { name: this.investor.name, link: 'InvestorView', params: { investorId: this.investor.id } };
          }
          if (breadcrumb.property) {
            return { name: this.property.address, link: 'PropertyView', params: { investorId: this.investor.id, propertyId: this.property.id } };
          }
          return breadcrumb;
        });
        this.breadcrumbList = breadcrumbs;
      }
    },
  },
  watch: {
    $route() {
      this.breadcrumbs();
    },
    isLoggedIn() {
      this.breadcrumbs();
    },
  },
};
</script>
