<template>
  <section id="intro">
    <div class="dropzone" v-bind="getRootProps()">
      <input v-bind="getInputProps()">
      <div class="investorAvatar">
        <img :src=investor.avatar.image alt="">
        <span class='overlay'><i class="icon solid fa-camera"></i></span>
      </div>
    </div>
    <header>
    <h2>{{investor.name}}</h2>
    <ul class="actions fit stacked">
      <li>
        <router-link
          class="button fit icon solid fa-user"
          :to="{ name: 'InvestorEditView',
          params: { investorId: investor.id }}">
            Edit Investor
        </router-link>
      </li>
      <li>
        <router-link
          class="button fit icon fa-home solid"
          :to="{ name: 'PropertyAddView' }">
            Add Property
        </router-link>
      </li>
      <li>
        <router-link
          class="button fit icon solid fa-percentage"
          :to="{ name: 'RatesView',
          params: { investorId: investor.id }}">
            Investor Rates
        </router-link>
      </li>
    </ul>
    </header>
  </section>
  <section class="blurb form">
    <h3>Primary Income</h3>
    <p>
      <input type="text" class="left" name="income"
        :value=finance.income.amount.toLocaleString()
        placeholder="Income"
        @change.prevent="editIncome($event)" >
      <i class="icon solid fa-dollar-sign input left" />
    </p>
    <h3>Other Deductions</h3>
    <p>
      <input type="text" class="left" name="deduction"
        :value=finance.deduction.amount.toLocaleString()
        placeholder="Deduction"
        @change.prevent="editDeduction($event)" >
      <i class="icon solid fa-dollar-sign input left" />
    </p>
    <h3>Contact details</h3>
    <p>{{investor.phone}}<br />
      {{investor.email}}<br />
      {{investor.address}}<br />
      {{investor.city}} {{investor.state}} {{investor.postcode}}</p>
  </section>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useDropzone } from 'vue3-dropzone';
import moment from 'moment';

export default {
  name: 'InvestorDetails',
  setup() {
    const store = useStore();
    const route = useRoute();
    const saveFiles = (files) => {
      store.commit('uploading', true);
      store.dispatch('editAvatar', {
        files,
        id: route.params.investorId,
        type: 'investor',
      });
    };

    function onDrop(acceptFiles, rejectReasons) {
      saveFiles(acceptFiles);
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  computed: {
    investor() {
      return this.$store.getters.getInvestor(this.$route.params.investorId);
    },
    finance() {
      return this.$store.getters.getFinance(this.investor);
    },
    defaults() {
      return this.$store.state.user.defaults;
    },
  },
  created() {
    const investor = this.$store.getters.getInvestor(this.$route.params.investorId);
    const { defaults } = this.$store.state.user;
    if (typeof investor.rates === 'undefined') {
      const rates = {};
      Object.keys(defaults).forEach((key) => {
        rates[key] = {
          ...defaults[key],
        };
      });
      this.$store.dispatch('editInvestor', {
        name: 'rates',
        value: rates,
        id: investor.id,
      });
    }
  },
  methods: {
    editInvestor(event) {
      this.$store.dispatch('editInvestor', {
        name: event.target.name,
        value: event.target.value,
        id: this.investor.id,
      });
    },
    editLivingExpenses(event) {
      this.$store.dispatch('editLivingExpenses', {
        amount: event.target.value,
        id: this.investor.id,
      });
    },
    editDeduction(event) {
      this.$store.dispatch('editDeduction', {
        amount: event.target.value,
        id: this.investor.id,
      });
    },
    editIncome(event) {
      this.$store.dispatch('editIncome', {
        amount: event.target.value,
        id: this.investor.id,
      });
    },
    editResidence(event, type) {
      this.$store.dispatch('editResidence', {
        type,
        amount: event.target.value,
        id: this.investor.id,
      });
    },
    moment,
  },
};
</script>
