<template>
  <article class="post">
    <header>
      <div class="title">
        <h2>Property Details</h2>
      </div>
    </header>
    <form class="form" >
      <div class="row gtr-uniform">
        <div class="col-12">
          <h3>Purchase</h3>
          <p>The purchasing entity determines how the tax implications of the property will be calculated. Different purchasing entities
            may also be subject to different lending criteria and other purchasing restrictions. It is important to discuss this with your
            accountant and mortgage broker.
          </p>
        </div>
        <div class="col-3 col-6-medium">
          <label>Purchase Type</label>
          <div>
            <select name="type" :value=property.finance.purchase.entity.type  @change.prevent="editEntity($event)">
              <option value="Personal" >Personal</option>
              <option value="Self Managed Super Fund" >Self Managed Super Fund</option>
              <option disabled value="Trust" >Trust - Coming Soon</option>
              <option disabled value="Company" >Company - Coming Soon</option>
            </select>
          </div>
        </div>
        <div v-show="!(property.finance.purchase.entity.type === 'Personal')" class="col-12">
          <h3>{{ property.finance.purchase.entity.type }}</h3>
        </div>
        <div v-show="!(property.finance.purchase.entity.type === 'Personal')" class="col-6">
          <label>{{ property.finance.purchase.entity.type }} Name</label>
          <div>
            <input type="text" name="name" :value=property.finance.purchase.entity.name placeholder="Purchasing Entity" @change.prevent="editEntity($event)" />
          </div>
        </div>
        <div v-show="(property.finance.purchase.entity.type === 'Company')" class="col-3 col-6-medium">
          <label>Company Income</label>
          <div>
            <input type="text" class="left" name="income" :value="parseInt(property.finance.purchase.entity.income, 10).toLocaleString()" placeholder="Company Income" @change.prevent="editEntityIncome($event)" /><i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div v-show="(property.finance.purchase.entity.type === 'Company')" class="col-3 col-6-medium">
          <label>Tax Rate</label>
          <div>
            <select name="taxRate" :value=property.finance.purchase.entity.taxRate  @change.prevent="editEntity($event)">
              <option value="reduced" >Reduced</option>
              <option value="full" >Full</option>
            </select>
          </div>
        </div>
        <div v-show="(property.finance.purchase.entity.type === 'Self Managed Super Fund')" class="col-3 col-6-medium">
          <label>Super Fund Income</label>
          <div>
            <input type="text" class="left" name="income" :value="parseInt(property.finance.purchase.entity.income, 10).toLocaleString()" @change.prevent="editEntityIncome($event)" placeholder="Super Fund Income" /><i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div v-show="(property.finance.purchase.entity.type === 'Trust')" class="col-3 col-6-medium">
          <label>Trust Income</label>
          <div>
            <input type="text" class="left" name="income" :value="(parseInt(investor.income.amount, 10) / 10).toLocaleString()" @change.prevent="editEntityIncome($event)" placeholder="Trust Income" /><i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-12">
          <h3>Price</h3>
          <p>Stamp duty is calculated differently if the land and building costs are in separate contracts. If both are included in a single contract, stamp duty will be
            calculated on the total purchase amount. If there is a separate land contract, stamp duty will calculated only on the purchase amount of the land.
          </p>
        </div>
        <div class="col-3 col-6-medium">
          <label>Contract Type</label>
          <div>
            <select name="type" :value=property.finance.purchase.type @change.prevent="editPropertyText('purchase', $event)">
              <option value="existing" >Single Contract</option>
              <option value="new" >Separate Land Contract</option>
            </select>
          </div>
        </div>
        <template v-if="property.finance.purchase.type === 'existing'" >
          <div class="col-3 col-6-medium">
            <label>Total Purchase</label>
            <div>
              <input type="text" class="left" name="property"  :value=parseInt(propertyValue(),10).toLocaleString() placeholder="Property" @change.prevent="editPropertyTotal($event)" /> <i class="icon solid fa-dollar-sign input left" />
            </div>
          </div>
        </template>
        <template v-if="property.finance.purchase.type === 'new'" >
          <div class="col-3 col-6-medium">
            <label>Building Purchase</label>
            <div>
              <input type="text" class="left" name="building"  :value=parseInt(property.finance.purchase.building,10).toLocaleString() placeholder="Building" @change.prevent="editProperty('purchase', $event)" /> <i class="icon solid fa-dollar-sign input left" />
            </div>
          </div>
          <div class="col-3 col-6-medium">
            <label>Land Purchase</label>
            <div>
              <input type="text" class="left" name="land" :value=parseInt(property.finance.purchase.land,10).toLocaleString() placeholder="Land" @change.prevent="editProperty('purchase', $event)" /> <i class="icon solid fa-dollar-sign input left" />
            </div>
          </div>
        </template>
        <div class="col-12">
          <hr />
          <h3>Purchasing Costs</h3>
          <p>Purchase costs can in some circumstances be capitalised onto your loan, however this should be
            discussed with your mortgage broker and this report assumes that they will be paid out of pocket.</p>
        </div>
        <div class="col-3 col-6-medium">
          <label>Stamp Duty</label>
          <div>
            <input type="text" class="left" name="stampDuty" disabled
              :value=parseInt(property.finance.purchaseCosts.stampDuty,10).toLocaleString()
              placeholder="Stamp Duty" >
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Transfer Title</label>
          <div>
            <input type="text" class="left" name="transferTitle" disabled
              :value=parseInt(property.finance.purchaseCosts.transferTitle,10).toLocaleString()
              placeholder="Transfer Title" />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Conveyancing</label>
          <div>
            <input type="text" class="left" name="conveyancingCosts"
              :value=parseInt(property.finance.purchaseCosts.conveyancingCosts,10).toLocaleString()
              placeholder="Conveyancing"
              @change.prevent="editProperty('purchaseCosts', $event)" />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Other</label>
          <div>
            <input type="text" class="left" name="other"
              :value=parseInt(property.finance.purchaseCosts.other,10).toLocaleString()
              placeholder="Other"
              @change.prevent="editProperty('purchaseCosts', $event)" />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-12">
          <hr />
          <h3>Rental Income</h3>
          <p>This is the income earned from operating a rental property. Vacancy rate can be affected by whether the property is listed as a short-
            or long-term rental and time between renters.
          </p>
        </div>
        <div class="col-3 col-6-medium">
          <label>Weekly Rent</label>
          <div>
            <input type="text" class="left" name="rent" :value=parseInt(property.finance.rentalIncome.rent,10).toLocaleString() placeholder="Weekly Rent" @change.prevent="editProperty('rentalIncome', $event)" /> <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Vacancy Rate</label>
          <div>
            <input type="text" class="right" name="vacancyRate" :value=parseFloat(property.finance.rentalIncome.vacancyRate).toFixed(2) placeholder="Vacancy Rate" @change.prevent="editProperty('rentalIncome', $event)"  />
            <i class="icon solid fa-percentage input right" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Actual Annual Rent</label>
          <div>
            <input type="text" disabled class="left" name="actualRent" :value=parseInt(actualAnnualRent,10).toLocaleString() />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-12">
          <hr />
          <h3>Rental Costs</h3>
          <p>These are the costs of owning and operating a rental property. They can be claimed as a cash deduction.
            These amounts may vary year-on-year depending on government requirements, the maintenance requirements of your property
            and events such as changing property managers, changing insurance providers or entering into a new rental agreement.</p>
        </div>
        <div class="col-3 col-6-medium">
          <label>Management Rate</label>
          <div>
            <input type="text" class="right" name="managementFee" :value=parseFloat(property.finance.rentalExpenses.managementFee.rate).toFixed(2) placeholder="Management Fee Rate" @change.prevent="editRentalCosts($event)" />
            <i class="icon solid fa-percentage input right" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Management Fees</label>
          <div>
            <input type="text" disabled class="left" name="managementFee" :value=parseInt(calcRentalCosts.managementFee,10).toLocaleString() />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Letting Fee Rate</label>
          <div>
            <input type="text" class="right" name="lettingFee" :value=parseFloat(property.finance.rentalExpenses.lettingFee.rate).toFixed(2) placeholder="Letting Fee Rate" @change.prevent="editRentalCosts($event)" />
            <i class="icon solid fa-times input right" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Letting Fees</label>
          <div>
            <input type="text" disabled class="left" name="lettingFee" :value=parseInt(calcRentalCosts.lettingFee,10).toLocaleString() />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Insurance</label>
          <div>
            <input type="text" class="left" name="insurance" :value=parseInt(property.finance.rentalExpenses.insurance,10).toLocaleString() placeholder="Insurance" @change.prevent="editProperty('rentalExpenses', $event)" />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Maintenance</label>
          <div>
            <input type="text" class="left" name="maintenance" :value=parseInt(property.finance.rentalExpenses.maintenance,10).toLocaleString() placeholder="Maintenance" @change.prevent="editProperty('rentalExpenses', $event)" />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Rates</label>
          <div>
            <input type="text" class="left" name="rates" :value=parseInt(property.finance.rentalExpenses.rates,10).toLocaleString() placeholder="Rates" @change.prevent="editProperty('rentalExpenses', $event)" />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Owner's Corporation</label>
          <div>
            <input type="text" class="left" name="oc" :value=parseInt(property.finance.rentalExpenses.oc,10).toLocaleString() placeholder="Owner's Corpoartion" @change.prevent="editProperty('rentalExpenses', $event)" />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-12">
          <hr />
          <h3>Depreciation</h3>
          <p>Depreciation may be claimed on the both the construction costs and fittings of the property. They are claimed as a non-cash deduction.</p>
        </div>
        <div class="col-3 col-6-medium">
          <label>Building Value</label>
          <div>
            <input :disabled="property.finance.purchase.type === 'new'" type="text" class="left" name="building"  :value=parseInt(property.finance.purchase.building,10).toLocaleString() placeholder="Building" @change.prevent="customBuildingValue($event)" /> <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Depreciation Rate</label>
          <div>
            <input type="text" class="right" name="buildingRate" :value=parseFloat(property.finance.depreciation.buildingRate).toFixed(2) placeholder="Building Depreciation Rate" @change.prevent="editProperty('depreciation', $event)" />
            <i class="icon solid fa-percentage input right" />
          </div>
        </div>
        <div class="col-12"></div>
        <div class="col-3 col-6-medium">
          <label>Value of fittings</label>
          <div>
            <input type="text" class="left" name="fittings" :value=parseInt(property.finance.depreciation.fittings,10).toLocaleString() placeholder="Fittings Value" @change.prevent="editProperty('depreciation', $event)" />
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-9 col-6-medium">
          <label>Note:</label>
          <div>
            <p>Fittings will be depreciated using the diminishing value method assuming an effective life of 15 years.</p>
          </div>
        </div>
      </div>
    </form>
  </article>
</template>

<script>

export default {
  name: 'PropertyPurchaseDetails',
  computed: {
    investor() {
      return this.$store.getters.getInvestor(this.$route.params.investorId);
    },
    property() {
      return this.$store.getters.getProperty(this.$route.params.propertyId);
    },
    actualAnnualRent() {
      return (this.property.finance.rentalIncome.rent * 52 * ((100 - this.property.finance.rentalIncome.vacancyRate) / 100));
    },
    calcRentalCosts() {
      const data = {
        managementFee: parseInt((this.actualAnnualRent * this.property.finance.rentalExpenses.managementFee.rate) / 100, 10),
        lettingFee: parseInt(this.property.finance.rentalIncome.rent * this.property.finance.rentalExpenses.lettingFee.rate, 10),
        ...this.property.finance.rentalCosts,
      };
      return data;
    },
  },
  methods: {
    propertyValue() {
      let value;
      if (this.property.finance.purchase.type === 'existing') {
        value = (parseInt(this.property.finance.purchase.land.toString().replace(/[^0-9.]/g, ''), 10) || 0) + (parseInt(this.property.finance.purchase.building.toString().replace(/[^0-9.]/g, ''), 10) || 0);
      } else if (this.property.finance.purchase.type === 'new') {
        value = parseInt(this.property.finance.purchase.land.toString().replace(/[^0-9.]/g, ''), 10) || 0;
      }
      this.$store.dispatch('editPurchaseCosts', { property: this.property, value });
      return value ?? 0;
    },
    customBuildingValue(event) {
      const land = this.propertyValue() - parseInt(event.target.value.toString().replace(/[^0-9.]/g, ''), 10);
      this.$store.dispatch('editPropertyFinance', {
        type: 'purchase',
        name: 'building',
        value: event.target.value.toString().replace(/[^0-9.]/g, '') || 0,
        id: this.property.id,
      })
        .then(() => {
          this.$store.dispatch('editPropertyFinance', {
            type: 'purchase',
            name: 'customBuilding',
            value: true,
            id: this.property.id,
          });
        })
        .then(() => {
          this.$store.dispatch('editPropertyFinance', {
            type: 'purchase',
            name: 'land',
            value: land || 0,
            id: this.property.id,
          });
        })
        .then(() => {
          this.propertyValue();
        });
    },
    editProperty(type, event) {
      this.$store.dispatch('editPropertyFinance', {
        type,
        name: event.target.name,
        value: event.target.value.toString().replace(/[^0-9.]/g, '') || 0,
        id: this.property.id,
      })
        .then(() => {
          this.propertyValue();
        });
    },
    editPropertyText(type, event) {
      this.$store.dispatch('editPropertyFinance', {
        type,
        name: event.target.name,
        value: event.target.value.toString(),
        id: this.property.id,
      })
        .then(() => {
          this.propertyValue();
        });
    },
    editEntity(event) {
      this.$store.dispatch('editEntity', {
        name: event.target.name,
        value: event.target.value.toString(),
        id: this.property.id,
      });
    },
    editEntityIncome(event) {
      this.$store.dispatch('editEntity', {
        name: event.target.name,
        value: event.target.value.toString().replace(/[^0-9.]/g, '') || 0,
        id: this.property.id,
      });
    },
    editRentalCosts(event) {
      this.$store.dispatch('editRentalCosts', {
        name: event.target.name,
        value: event.target.value.toString().replace(/[^0-9.]/g, '') || 0,
        id: this.property.id,
      });
    },
    editPropertyCosts(event) {
      this.$store.dispatch('editPropertyFinance', {
        type: 'purchaseCosts',
        name: event.target.name,
        value: event.target.value.toString().replace(/[^0-9.]/g, '') || 0,
        id: this.property.id,
      });
    },
    editPropertyTotal(event) {
      if (this.property.finance.purchase.customBuilding) {
        const land = parseInt(event.target.value.toString().replace(/[^0-9.]/g, ''), 10) - this.property.finance.purchase.building;
        this.$store.dispatch('editPropertyFinance', {
          type: 'purchase',
          name: 'land',
          value: land,
          id: this.property.id,
        });
      } else {
        this.$store.dispatch('editPropertyFinance', {
          type: 'purchase',
          name: 'building',
          value: Math.round(((parseInt(event.target.value.toString().replace(/[^0-9.]/g, ''), 10) || 0) * 0.7)).toString(),
          id: this.property.id,
        });
        this.$store.dispatch('editPropertyFinance', {
          type: 'purchase',
          name: 'land',
          value: Math.round(((parseInt(event.target.value.toString().replace(/[^0-9.]/g, ''), 10) || 0) * 0.3)).toString(),
          id: this.property.id,
        });
      }
    },
  },
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style  lang="scss">
  :root {
    --toggle-width: 100%;
    --toggle-ring-width: 100%;
  }
</style>
