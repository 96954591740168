<template>
  <section id="sidebar">
    <PropertyDetails />
  </section>
  <div id="main">
    <PropertyPurchaseDetails />
    <PropertyFinanceDetails />
    <template v-if="property.finance.purchase.entity.type === 'Personal'">
      <Spreadsheet />
    </template>
    <template v-if="property.finance.purchase.entity.type === 'Self Managed Super Fund'">
      <SpreadsheetSMSF />
    </template>
    <ul class='actions fit'>
      <li><a class='button fit icon fa-file solid' @click.prevent="report(false)" gtm-id='summary' >Summary Report</a></li>
      <li><a class='button fit icon fa-file-alt solid' @click.prevent="report(true)" gtm-id='details' >Detailed Report</a></li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import PropertyDetails from '@/components/PropertyDetails.vue';
import PropertyPurchaseDetails from '@/components/PropertyPurchaseDetails.vue';
import PropertyFinanceDetails from '@/components/PropertyFinanceDetails.vue';
import Spreadsheet from '@/components/Spreadsheet.vue';
import SpreadsheetSMSF from '@/components/SpreadsheetSMSF.vue';

export default {
  name: 'PropertyView',
  components: {
    PropertyDetails,
    PropertyPurchaseDetails,
    PropertyFinanceDetails,
    Spreadsheet,
    SpreadsheetSMSF,
  },
  computed: {
    property() {
      return this.$store.getters.getProperty(this.$route.params.propertyId);
    },
    investor() {
      return this.$store.getters.getInvestor(this.$route.params.investorId);
    },
    account() {
      return this.$store.state.account;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    report(details) {
      let SERVER_URL = 'https://server.augurinvestor.com.au/';
      let type = 'Summary';
      const {
        $store,
        user,
        property,
        investor,
        account,
      } = this;
      if (details) {
        SERVER_URL = 'https://server.augurinvestor.com.au/details.php';
        type = 'Detailed';
      }
      $store.dispatch('generateReport')
        .then((id) => {
          const data = {
            id,
            type,
            user,
            property,
            investor,
            account,
            ...$store.state.spreadsheet,
          };
          axios.post(SERVER_URL, JSON.stringify(data))
            .then(() => {
              const url = `https://server.augurinvestor.com.au/pdf/${data.investor.name} - ${data.property.address} - ${data.type} ${id}.pdf`;
              window.open(url, '_blank');
            });
        });
    },
  },
};
</script>
