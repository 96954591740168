<template>
  <div id="main">
    <article class="post">
      <header>
        <div class="title">
          <h2>Personal Details - {{ user.name }}</h2>
          <p>Modify personal details including name, email and password.</p>
        </div>
      </header>
      <form class="form" >
        <div class="row gtr-uniform">
          <div class="col-12">
            <h3>Update Personal Details</h3>
          </div>
          <div class="col-3 col-12-medium">
            <label>Name</label>
            <div>
              <input type="text" name="name" :value=user.name placeholder="Name" @change.prevent="editUserName($event)"/>
            </div>
          </div>
          <div class="col-6">
            <label>Email</label>
            <div>
              <input type="email" name="email" v-model="newEmail" placeholder="Email" />
            </div>
          </div>
          <div class="col-3 col-6-medium">
            <label>&nbsp;</label>
            <div>
              <button class="button fit" :disabled="(user.email === newEmail)" @click.prevent="loginPrompt('email')" >Change Email</button>
            </div>
          </div>
          <div class="col-12">
            <h3>Change Password</h3>
          </div>
          <div class="col-3 col-6-medium">
            <label>Password</label>
            <div>
              <input type="password" v-model="newPassword" placeholder="Password" />
            </div>
          </div>
          <div class="col-3 col-6-medium">
            <label>Confirm</label>
            <div>
              <input type="password" v-model="confirm" placeholder="Confirm" />
            </div>
          </div>
          <div class="col-3 col-6-medium">
            <label>&nbsp;</label>
            <div>
              <button class="button fit" :disabled=!match @click.prevent="loginPrompt('password')" >Change Password</button>
            </div>
          </div>
          <div class="col-3 col-6-medium">
            <label>&nbsp;</label>
            <div>
              <input type="text" disabled :value="!match ? newPassword === '' ? '' : 'Passwords do not match' : ''" />
            </div>
          </div>
        </div>
      </form>
    </article>
  </div>
  <div v-if="modal">
    <article class="modal post">
      <i class="close icon solid fa-times" @click.prevent=closeModal()></i>
      <h3>Password</h3>
      <p>You must enter your current password to continue</p>
      <div class="form">
        <p>
          <input type="password" placeholder="Password" v-model="currentPassword" />
        </p>
        <button class="button" :disabled=!current @click.prevent="login()" >Submit</button>
      </div>
    </article>
    <div id="shade"></div>
  </div>
</template>

<script>
export default {
  name: 'UserView',
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirm: '',
      reason: '',
      modal: false,
      newEmail: this.$store.state.user.email,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    account() {
      return this.$store.state.account;
    },
    match() {
      return this.newPassword === this.confirm && this.newPassword !== '' && this.confirm !== '';
    },
    current() {
      return this.currentPassword !== '';
    },
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('login', { email: this.user.email, password: this.currentPassword });
        this.modal = false;
        if (this.reason === 'password') {
          this.editUserPassword();
        } else {
          this.editUserEmail();
        }
      } catch {
        console.log('error');
      }
    },
    closeModal() {
      this.modal = false;
    },
    editUserName(event) {
      this.$store.dispatch('editUserName', {
        name: event.target.value.toString(),
        id: this.user.id,
      });
    },
    loginPrompt(reason) {
      this.reason = reason;
      this.modal = true;
    },
    editUserPassword() {
      this.$store.dispatch('editUserPassword', {
        password: this.newPassword,
      });
      this.reason = '';
      this.event = '';
      this.newPassword = '';
      this.currentPassword = '';
    },
    editUserEmail() {
      this.$store.dispatch('editUserEmail', {
        email: this.newEmail,
        id: this.user.id,
      });
      this.reason = '';
      this.event = '';
      this.newEmail = this.user.email;
    },
  },
};
</script>
