<template>
  <article class="post">
    <header>
      <div class="title">
        <h2>SMSF Projections</h2>
        <p>The following projections are based on the information provided above. Actual performance of your investment property will depend
          on your borrowing conditions, tax returns and other external factors. It is important to ensure you have engaged the appropriate mortgage broker,
          accountant, quanitity surveyor, conveyancer and/or financial planner in order to achieve these or similar outcomes.
        </p>
      </div>
    </header>
    <div id="spreadsheet" class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Property</th>
                <th v-for="year in table.year" :key="year" class="align-center" >
                  {{ year }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in table.items" :key="row.id">
                <th v-if="row.title" >{{ row.label }} </th>
                <td v-else >{{ row.label }}</td>
                <td v-for="value, index in row.values" :key="index">
                  <div v-if="row.percent || (row.id === 'interest' && index === 0)  || (row.id === 'rentalExpenses' && index === 0) || (row.id === 'buildingDepreciation' && index === 0)" >
                    {{ parseFloat(value).toFixed(2) }}%
                  </div>
                  <div v-else-if="(row.id === 'totalDeductions' && index === 0)  || (row.id === 'weeklyCashFlow' && index === 0) || (row.id === 'totalIncome' && index === 0) || (row.id === 'preTaxCashFlow' && index === 0)" >
                    &nbsp;
                  </div>
                  <div v-else >
                    {{ 0 > value ? '-' : '' }}${{ Math.round(Math.abs(value)).toLocaleString() }}
                  </div>
                </td>
                <td v-for="n in (6 - row.values.length)" :key="n"></td>
              </tr>
            </tbody>
          </table>
        </div>
  </article>
</template>

<script>
import * as formulajs from '@formulajs/formulajs';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Spreadsheet',
  computed: {
    ...mapGetters([
      'getInvestor',
      'getFinance',
      'getProperty',
    ]),
    ...mapState({
      defaults: (state) => state.user.defaults,
    }),
    investor() {
      return this.getInvestor(this.$route.params.investorId);
    },
    finance() {
      return this.getFinance(this.investor);
    },
    property() {
      return this.getProperty(this.$route.params.propertyId);
    },
    currentYear() {
      const current = new Date();
      return current.getFullYear();
    },
    table() {
      const items = this.data.items.map((item) => ({ ...item, values: item.values.filter((i, index) => [0, 1, 2, 3, 5, 10].includes(index)) }));
      const extras = this.data.extras.map((item) => ({ ...item, values: item.values.filter((i, index) => [0, 1, 2, 3, 5, 10].includes(index)) }));
      const longTermProjections = this.data.items.map((item) => ({ ...item, values: item.values.filter((i, index) => [15, 20].includes(index)) }));
      const longTermExtras = this.data.extras.map((item) => ({ ...item, values: item.values.filter((i, index) => [15, 20].includes(index)) }));
      const data = {
        year: this.data.year.filter((item, index) => [0, 1, 2, 3, 5, 10].includes(index)),
        items,
        extras,
        longTermProjections,
        longTermExtras,
        taxObligation: this.data.taxObligation,
        newTaxObligation: this.data.newTaxObligation,
      };
      this.$store.commit('spreadsheet', data);
      return data;
    },
    data() {
      const years = [];
      const propertyValue = [];
      const purchaseCosts = [];
      const investment = [];
      const loan = [];
      const equity = [];
      const capitalGrowth = [];
      const inflation = [];
      const rentalIncome = [];
      const totalIncome = [];
      const rentalExpense = [];
      const interest = [];
      const ptCashFlow = [];
      const buildingDepreciation = [];
      const fittingDepreciation = [];
      const loanCosts = [];
      const depreciationBalance = [];
      const totalExpenses = [];
      const ncDeductions = [];
      const totalDeductions = [];
      const taxCredit = [];
      const income = [];
      const atCashFlow = [];
      const weeklyCashFlow = [];
      const sellingCosts = [];
      const todaysValue = [];
      const todaysEquity = [];
      const totalInvestment = [];
      const annualReturn = [];
      const saleAnnualReturn = [];
      let taxObligation = 0;
      let newTaxObligation = 0;
      const { rates } = this.investor;
      for (let i = 0; i < 21; i++) {
        const year = this.currentYear + i;
        years.push(year);

        if (rates.capitalGrowth && rates.cpi) {
          // Property Value
          const ipv = parseInt(this.property.finance.purchase.land, 10) + parseInt(this.property.finance.purchase.building, 10);
          const cg = parseFloat(rates.capitalGrowth ? rates.capitalGrowth.rate : this.defaults.capitalGrowth.rate);
          let pv;
          const cpi = parseFloat(rates.cpi ? rates.cpi.rate : this.defaults.cpi.rate);

          let ri;
          let ti;
          let tre = 0;
          let re;
          let inr;
          let ln;
          let tlc = 0;
          let lc = 0;

          Object.values(this.property.finance.loanCosts).forEach((cost) => {
            tlc += parseInt(cost, 10);
          });
          Object.values(this.property.finance.rentalExpenses).forEach((cost) => {
            if (typeof cost !== 'object') {
              tre += parseInt(cost, 10);
            }
          });

          const actualAnnualRent = this.property.finance.rentalIncome.rent * 52 * ((100 - this.property.finance.rentalIncome.vacancyRate) / 100);
          const managementFee = parseInt((actualAnnualRent * this.property.finance.rentalExpenses.managementFee.rate) / 100, 10);
          const lettingFee = parseInt(this.property.finance.rentalIncome.rent * this.property.finance.rentalExpenses.lettingFee.rate, 10);
          tre += managementFee + lettingFee;

          let bd = parseInt(this.property.finance.purchase.building, 10) * (this.property.finance.depreciation.buildingRate / 100);

          let ce;
          let ncd;
          let td;

          let fd;
          let fdb;
          let inc;
          let taxc;

          if (i < 1) {
            pv = ipv;
            let pc = 0;
            Object.values(this.property.finance.purchaseCosts).forEach((cost) => {
              pc += parseInt(cost, 10);
            });
            fd = this.property.finance.depreciation.fittings;
            fdb = this.property.finance.depreciation.fittings;
            lc = tlc;
            loanCosts.push(lc);
            ln = parseInt(ipv, 10) - parseInt(this.property.finance.loan.investment, 10);
            purchaseCosts.push(pc);
            investment.push(parseInt(this.property.finance.loan.investment, 10));
            ri = this.property.finance.rentalIncome.rent;
            taxc = this.property.finance.purchase.entity.income;
            inc = this.property.finance.purchase.entity.income;
            re = (tre / (ri * 52)) * 100;
            inr = parseFloat(this.property.finance.loan.interestRate);
            bd = this.property.finance.depreciation.buildingRate;
            ce = '';
            ncd = '';
            td = '';
            ti = '';
          } else {
            if (this.property.finance.loan.type === 'pi') {
              inr = parseInt(-formulajs.CUMIPMT(this.property.finance.loan.interestRate / 12 / 100, 300, loan[0], ((i - 1) * 12) + 1, i * 12, 0), 10);
              const repayments = parseInt(formulajs.PMT(this.property.finance.loan.interestRate / 100 / 12, 300, -loan[0], 0, 0) * 12, 10);
              const principleChange = repayments - inr;
              ln = loan[i - 1] - principleChange;
            } else {
              ln = loan[i - 1];
              inr = parseInt((ln * (parseFloat(this.property.finance.loan.interestRate) / 100)), 10);
            }
            fd = depreciationBalance[i - 1] * (2 / 15);
            fdb = depreciationBalance[i - 1] - fd;
            pv = propertyValue[i - 1] + (propertyValue[i - 1] * (cg / 100));
            ri = rentalIncome[i - 1] + (rentalIncome[i - 1] * (cpi / 100));
            ti = ri;
            inc = parseInt(income[i - 1], 10) + parseInt(income[i - 1] * (cpi / 100), 10);
            re = rentalExpense[i - 1] + (rentalExpense[i - 1] * (cpi / 100));
            if (i <= parseInt(this.property.finance.loan.writeOff, 10)) {
              lc = tlc / parseInt(this.property.finance.loan.writeOff, 10);
              loanCosts.push(lc);
            }
          }
          if (i === 1) {
            ri = this.property.finance.rentalIncome.rent * 52 * ((100 - this.property.finance.rentalIncome.vacancyRate) / 100);
            ti = ri;
            re = tre;
          }

          propertyValue.push(parseInt(pv.toFixed(0), 10));
          loan.push(ln);
          equity.push(parseInt(pv, 10) - parseInt(ln, 10));
          capitalGrowth.push(cg);
          inflation.push(cpi);
          rentalIncome.push(parseInt(ri, 10));
          totalIncome.push(ti);
          interest.push(inr);
          buildingDepreciation.push(bd);
          fittingDepreciation.push(fd);
          depreciationBalance.push(fdb);
          income.push(inc);
          rentalExpense.push(re);
          const newinc = inc;
          if (i >= 1) {
            ce = re + inr;
            ncd = bd + lc + fd;
            td = ce + ncd;
            const currtx = inc * 0.15;
            const newtx = (inc - td + ri) * 0.15;
            taxc = currtx - newtx;
            if (i === 1) {
              taxObligation = currtx;
              newTaxObligation = newtx;
            }
          }
          taxCredit.push(taxc);
          totalExpenses.push(ce);
          ncDeductions.push(ncd);
          totalDeductions.push(td);

          if (i < 1) {
            ptCashFlow.push(0 - parseInt(investment[i], 10) - parseInt(purchaseCosts[i], 10) - parseInt(loanCosts[i], 10));
            atCashFlow.push(0 - parseInt(investment[i], 10) - parseInt(purchaseCosts[i], 10) - parseInt(loanCosts[i], 10));
            weeklyCashFlow.push('');
          } else {
            ptCashFlow.push((parseInt(rentalIncome[i], 10)) - (parseInt(this.property.finance.loan.repayment * 12, 10)) - (parseInt(rentalExpense[i], 10)));
            atCashFlow.push(parseInt(ptCashFlow[i], 10) + parseInt(taxCredit[i], 10));
            weeklyCashFlow.push(atCashFlow[i] / 52);
          }
          const sc = (parseInt(propertyValue[i], 10) * 0.02) + 2000;
          const aq = (parseInt(ipv, 10) + parseInt(purchaseCosts[0], 10)) - parseInt(depreciationBalance[0], 10);
          const capgain = parseInt(propertyValue[i], 10) - parseInt(sc, 10) - parseInt(depreciationBalance[i], 10) - parseInt(aq, 10);
          const bdc = parseInt(this.property.finance.purchase.building, 10) * (this.property.finance.depreciation.buildingRate / 100) * i;
          const taxcg = (parseInt(capgain, 10) + parseInt(bdc, 10)) / 2;
          const cginc = parseInt(newinc, 10) + parseInt(taxcg, 10);
          const capgaintax = (cginc * 0.15) - newTaxObligation;
          sellingCosts.push(capgaintax + sc);

          const tv = parseInt(atCashFlow[i] / ((1 + (cpi / 100)) ** i), 10);
          const te = parseInt(equity[i] / ((1 + (cpi / 100)) ** i), 10);
          todaysValue.push(tv);
          todaysEquity.push(te);
          if (i < 1) {
            totalInvestment.push(todaysValue[i]);
          } else {
            totalInvestment.push(totalInvestment[i - 1] + todaysValue[i]);
          }
          const aseq = equity[i] - sellingCosts[i];

          const cashflow = [...atCashFlow];
          cashflow[cashflow.length - 1] = cashflow[cashflow.length - 1] + equity[i];
          const asCashFlow = [...atCashFlow];
          asCashFlow[asCashFlow.length - 1] = asCashFlow[asCashFlow.length - 1] + aseq;

          const IRR = parseFloat(formulajs.IRR(cashflow, 0.1)).toFixed(10) * 100;
          const asIRR = parseFloat(formulajs.IRR(asCashFlow, 0.1)).toFixed(10) * 100;

          annualReturn.push(IRR);
          saleAnnualReturn.push(asIRR);
        }
      }
      const table = {
        year: years,
        depreciationBalance,
        taxObligation,
        newTaxObligation,
        income,
        extras: [
          {
            label: 'Selling Costs',
            id: 'sellingCosts',
            values: sellingCosts,
          },
          {
            label: 'Todays Value',
            id: 'todaysValue',
            values: todaysValue,
          },
          {
            label: 'Todays Equity',
            id: 'todaysEquity',
            values: todaysEquity,
          },
          {
            label: 'Total Investment',
            id: 'totalInvestment',
            values: totalInvestment,
          },
          {
            label: 'Annual Return',
            id: 'annualReturn',
            values: annualReturn,
          },
          {
            label: 'After Sale Annual Return',
            id: 'saleAnnualReturn',
            values: saleAnnualReturn,
          },
          {
            label: 'Capital Growth',
            id: 'capitalGrowth',
            values: capitalGrowth,
            percent: true,
          },
          {
            label: 'Inflation',
            id: 'inflation',
            values: inflation,
            percent: true,
          },
        ],
        items: [
          {
            label: 'Property Value',
            id: 'propertyValue',
            values: propertyValue,
          },
          {
            label: 'Purchase Costs',
            id: 'purchaseCosts',
            values: purchaseCosts,
          },
          {
            label: 'Deposit',
            id: 'investment',
            values: investment,
          },
          {
            label: 'Loan Amount',
            id: 'loan',
            values: loan,
          },
          {
            label: 'Equity',
            id: 'equity',
            values: equity,
          },
          // {
          //   label: 'Capital Growth',
          //   id: 'capitalGrowth',
          //   values: capitalGrowth,
          //   percent: true,
          // },
          // {
          //   label: 'Inflation',
          //   id: 'inflation',
          //   values: inflation,
          //   percent: true,
          // },
          {
            label: 'Rental Income',
            id: 'rentalIncome',
            values: rentalIncome,
          },
          {
            label: 'Deductions',
            id: 'deductions',
            values: [],
            title: true,
          },
          {
            label: 'Interest',
            id: 'interest',
            values: interest,
          },
          {
            label: 'Rental Expenses',
            id: 'rentalExpenses',
            values: rentalExpense,
          },
          {
            label: 'Depreciation of Building',
            id: 'buildingDepreciation',
            values: buildingDepreciation,
          },
          {
            label: 'Depreciation of Fittings',
            id: 'fittingDepreciation',
            values: fittingDepreciation,
          },
          {
            label: 'Loan Costs',
            id: 'loanCosts',
            values: loanCosts,
          },
          {
            label: 'SMSF Tax Position',
            id: 'taxPosition',
            values: [],
            title: true,
          },
          {
            label: 'Total Property Income',
            id: 'totalIncome',
            values: totalIncome,
          },
          {
            label: 'Cash Deductions',
            id: 'totalExpenses',
            values: totalExpenses,
          },
          {
            label: 'Non-Cash Deductions',
            id: 'ncDeductions',
            values: ncDeductions,
          },
          {
            label: 'Total Deductions',
            id: 'totalDeductions',
            values: totalDeductions,
          },
          {
            label: 'Before Tax Cash Flow',
            id: 'preTaxCashFlow',
            values: ptCashFlow,
          },
          {
            label: 'Tax Savings on SMSF Income',
            id: 'taxCredit',
            values: taxCredit,
          },
          {
            label: 'After Tax Cash Flow',
            id: 'afterTaxCashFlow',
            values: atCashFlow,
          },
          {
            label: 'Projections',
            id: 'projections',
            values: [],
            title: true,
          },
          {
            label: 'After Tax Cash Flow (Weekly)',
            id: 'weeklyCashFlow',
            values: weeklyCashFlow,
          },
          {
            label: 'Equity',
            id: 'equity',
            values: equity,
          },
        ],
      };
      return table;
    },
  },
};
</script>
