<template>
  <section id="sidebar">
    <div>
      <div v-bind="getRootProps()">
        <input v-bind="getInputProps()">
          <div class="avatar">
            <img :src=avatar.image alt="">
            <span class='overlay'><i class="icon solid fa-camera"></i></span>
          </div>
      </div>
    </div>
  </section>
  <div id="main">
    <article class="post">
      <header>
        <div class="title">
          <h2>New Property</h2>
        </div>
      </header>
      <form class="form" >
        <div class="row gtr-uniform">
          <div class="col-12">
            <input type="text" name="address" v-model=property.address placeholder="Address" />
          </div>
          <div class="col-6 col-12-xsmall">
            <input type="text" name="city" v-model=property.city placeholder="City" />
          </div>
          <div class="col-3 col-6-xsmall">
            <select name="state" required v-model=property.state >
              <option selected value="VIC">VIC</option>
              <option value="NSW">NSW</option>
              <option value="WA">WA</option>
              <option value="QLD">QLD</option>
              <option value="TAS">TAS</option>
              <option value="SA">SA</option>
              <option value="ACT">ACT</option>
              <option value="NT">NT</option>
            </select>
          </div>
          <div class="col-3 col-6-xsmall">
            <input type="text" name="postcode" v-model=property.postcode
              placeholder="Postcode" />
          </div>
          <div class="col-12">
            <h3>Property Details</h3>
          </div>
          <div class="col-4 col-12-xsmall">
            <label>Bedrooms</label>
            <select name="bedrooms" v-model=property.bedrooms placeholder="Bedrooms">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5+</option>
            </select>
          </div>
          <div class="col-4 col-12-xsmall">
            <label>Bathrooms</label>
            <select name="bathrooms" v-model=property.bathrooms placeholder="Bathrooms">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5+</option>
            </select>
          </div>
          <div class="col-4 col-12-xsmall">
            <label>Car Spaces</label>
            <select name="carSpaces" v-model=property.carSpaces placeholder="Car Spaces">
              <option value="0">No Car Spaces</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5+</option>
            </select>
          </div>
          <div class="col-12">
            <textarea name="description" v-model=property.description placeholder="Description/Notes"></textarea>
          </div>
          <div class="col-12">
            <h3>Ownership</h3>
          </div>
          <div class="col-6"><h4>Owner</h4></div>
          <div class="col-4"><h4>Ownership percentage</h4></div>
          <template :key=index v-for="(owner, index) in ownership">
            <template v-if="owner.key == investor.id">
              <div class="col-6">
                <input disabled type="text" name="initialowner-{{owner.key}}" :value=owner.investor.name />
              </div>
              <div class="col-4">
                <input disabled type="text" name="initialowner-percentage-{{owner.key}}" :value=owner.percentage /> <i class="icon solid fa-percentage input" />
              </div>
            </template>
            <template v-else>
              <div class="col-6">
                <select name="additionalOwner" placeholder="Additional Owner" v-model="owner.investor.id" @change.prevent="updateOwner($event, index)">
                  <option v-for="investor in investors" :key=investor.id :value=investor.id >{{ investor.name }}</option>
                </select>
              </div>
              <div class="col-4">
                <input type="text" name="initialowner-percentage-{{owner.key}}" @change.prevent="updateOwnership($event, index)" :value=parseInt(owner.percentage,10) /> <i class="icon solid fa-percentage input" />
              </div>
              <div class="col-2">
                <ul class="actions right">
                  <li>
                    <a class="button fit icon solid fa-trash" @click="deleteOwner($event, index)" >Delete</a>
                  </li>
                </ul>
              </div>
            </template>
          </template>
        </div>
      </form>
      <ul class="actions right">
        <li>
          <a :class=uploading class="button primary fit icon solid fa-save" @click=addProperty >Save</a>
        </li>
        <li>
          <a class="button fit icon solid fa-plus" @click=addOwner >Add additional Owner</a>
        </li>
      </ul>
    </article>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useDropzone } from 'vue3-dropzone';

export default {
  name: 'PropertyAddView',
  setup() {
    const store = useStore();
    const saveFiles = (files) => {
      store.commit('uploading', true);
      store.dispatch('temporaryAvatar', {
        files,
        type: 'property',
      });
    };

    function onDrop(acceptFiles, rejectReasons) {
      saveFiles(acceptFiles);
      if (rejectReasons) {
        console.log(rejectReasons);
      }
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  data() {
    return {
      property: {
        name: '',
        address: '',
        city: '',
        state: 'VIC',
        postcode: '',
        description: '',
        bedrooms: '',
        bathrooms: '',
        carSpaces: '',
      },
      owners: [],
      ownership: [],
    };
  },
  computed: {
    uploading() {
      return (this.$store.state.uploading ? 'disabled' : '');
    },
    avatar() {
      if (this.$store.state.temporaryAvatar) {
        return this.$store.state.temporaryAvatar;
      }
      return { image: '/images/property.svg' };
    },
    investors() {
      const otherOwners = this.$store.getters.investors.filter((element) => this.investor.id !== element.id);
      return otherOwners;
    },
    investor() {
      return this.$store.getters.getInvestor(this.$route.params.investorId);
    },
  },
  methods: {
    updateOwner(event, index) {
      this.ownership[index] = {
        ...this.ownership[index],
        key: event.target.value,
        investor: this.$store.getters.getInvestor(event.target.value),
      };
      this.owners[index] = event.target.value;
    },
    deleteOwner(event, index) {
      this.ownership.splice(index, 1);
      this.owners.splice(index, 1);
    },
    updateOwnership(event, index) {
      this.ownership[index] = {
        ...this.ownership[index],
        percentage: parseInt(event.target.value, 10),
      };
      let totalPercentage = 0;
      this.ownership.forEach((item, i) => {
        if (i > 0) {
          totalPercentage += parseInt(item.percentage, 10);
        }
      });
      this.ownership[0] = {
        ...this.ownership[0],
        percentage: 100 - totalPercentage,
      };
    },
    addProperty() {
      this.property.ownership = this.ownership;
      this.property.owners = this.owners;
      this.$store.dispatch('addProperty', { ...this.property, avatar: this.avatar });
      this.$router.push({ name: 'InvestorView', params: { investorId: this.$route.params.investorId } });
    },
    addOwner() {
      this.ownership.push(
        {
          key: 'new',
          investor: '',
          percentage: 0,
        },
      );
      this.owners.push('new');
    },
  },
  watch: {
    $route() {
      this.$store.commit('clearTemporaryAvatar');
    },
  },
  created() {
    this.ownership = [
      {
        key: this.investor.id,
        investor: this.investor,
        percentage: 100,
      },
    ];
    this.owners = [this.investor.id];
  },
};
</script>
