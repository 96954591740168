<template>
  <div id="main">
    <article class="post">
      <header>
        <div class="title">
          <h2>Rate Table - {{ investor.name }}</h2>
          <p>Modify the growth and inflation rates used in this investor's projections.</p>
        </div>
      </header>
      <section>
        <div id="rates-table" class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th></th>
                <th class="align-center" >Same as CPI?</th>
                <th class="align-center" >Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2">Inflation Rate (CPI)</td>
                <td>
                  <input type="text" name="cpi" :value="parseFloat(investor.rates && investor.rates.cpi ? investor.rates.cpi.rate : defaults.cpi.rate).toFixed(2)" @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
                </td>
              </tr>
              <tr>
                <td>Rental Income Rate</td>
                <td class="align-center" >
                  <input type="checkbox" id="rentalIncome" name="rentalIncome"
                    v-model=defaults.rentalIncome.sameAsCpi @change.prevent="sameAsCpi($event)">
                  <label for="rentalIncome"></label>
                </td>
                <td>
                  <input :disabled=defaults.rentalIncome.sameAsCpi type="text" name="rentalIncome" :value="parseFloat(investor.rates && investor.rates.rentalIncome ? (investor.rates.rentalIncome.sameAsCpi ? investor.rates.cpi.rate : investor.rates.rentalIncome.rate) : (defaults.rentalIncome.sameAsCpi ? defaults.cpi.rate : defaults.rentalIncome.rate)).toFixed(2)" @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
                </td>
              </tr>
              <tr>
                <td>Rental Expense Rate</td>
                <td class="align-center" >
                  <input type="checkbox" id="rentalExpense" name="rentalExpense"
                    v-model=defaults.rentalExpense.sameAsCpi @change.prevent="sameAsCpi($event)">
                  <label for="rentalExpense"></label>
                </td>
                <td>
                  <input :disabled=defaults.rentalExpense.sameAsCpi type="text" name="rentalExpense" :value="parseFloat(investor.rates && investor.rates.rentalExpense ? (investor.rates.rentalExpense.sameAsCpi ? investor.rates.cpi.rate : investor.rates.rentalExpense.rate) : (defaults.rentalExpense.sameAsCpi ? defaults.cpi.rate : defaults.rentalExpense.rate)).toFixed(2)" @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
                </td>
              </tr>
              <tr>
                <td>Taxable Income Rate</td>
                <td class="align-center" >
                  <input type="checkbox" id="taxableIncome" name="taxableIncome"
                    v-model=defaults.taxableIncome.sameAsCpi @change.prevent="sameAsCpi($event)">
                  <label for="taxableIncome"></label>
                </td>
                <td>
                  <input :disabled=defaults.taxableIncome.sameAsCpi type="text" name="taxableIncome" :value="parseFloat(investor.rates && investor.rates.taxableIncome ? (investor.rates.taxableIncome.sameAsCpi ? investor.rates.cpi.rate : investor.rates.taxableIncome.rate) : (defaults.taxableIncome.sameAsCpi ? defaults.cpi.rate : defaults.taxableIncome.rate)).toFixed(2)" @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
                </td>
              </tr>
              <!-- <tr>
                <td>Living Expense Rate</td>
                <td class="align-center" >
                  <input type="checkbox" id="livingExpense" name="livingExpense"
                    v-model=defaults.livingExpense.sameAsCpi @change.prevent="sameAsCpi($event)">
                  <label for="livingExpense"></label>
                </td>
                <td>
                  <input :disabled=defaults.livingExpense.sameAsCpi type="text" name="livingExpense" :value="parseFloat(investor.rates && investor.rates.livingExpense ? investor.rates.livingExpense.rate : (defaults.livingExpense.sameAsCpi ? defaults.cpi.rate : defaults.livingExpense.rate)).toFixed(2)" @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
                </td>
              </tr> -->
              <tr>
                <td colspan="2">Capital Growth Rate</td>
                <td>
                  <input type="text" name="capitalGrowth" :value=parseFloat(defaults.capitalGrowth.rate).toFixed(2) @change.prevent="rateChange($event)" /> <i class="icon solid fa-percentage input" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <ul class="actions right">
        <li>
          <router-link
            class="button fit"
            :to="{ name: 'InvestorView',
            params: { investorId: investor.id }}">
              Done
          </router-link>
        </li>
      </ul>
    </article>
  </div>
</template>

<script>

export default {
  name: 'RatesView',
  computed: {
    investor() {
      return this.$store.getters.getInvestor(this.$route.params.investorId);
    },
    defaults() {
      return this.$store.state.user.defaults;
    },
    // currentYear() {
    //   const current = new Date();
    //   return current.getFullYear();
    // },
    // table() {
    //   const cpiArr = [];
    //   const rentalIncomeArr = [];
    //   const rentalExpenseArr = [];
    //   const taxableIncomeArr = [];
    //   const livingExpenseArr = [];
    //   const capitalGrowthArr = [];
    //   const yearArr = [];
    //   const { rates } = this.investor;
    //   let data = {};
    //   let cpi;
    //   let rentalIncome;
    //   let rentalExpense;
    //   let taxableIncome;
    //   let livingExpense;
    //   let capitalGrowth;
    //   for (let i = 0; i < 5; i++) {
    //     const year = this.currentYear + i;
    //     if (rates.cpi) {
    //       cpi = rates.cpi.rates.find(
    //         (rate) => parseInt(rate.date, 10) === parseInt(year, 10),
    //       );
    //       cpi = cpi ? cpi.rate : this.defaults.cpi.rate;
    //     } else {
    //       cpi = {
    //         rate: this.defaults.cpi,
    //       };
    //     }
    //     if (rates.rentalIncome) {
    //       rentalIncome = rates.rentalIncome.rates.find(
    //         (rate) => parseInt(rate.date, 10) === parseInt(year, 10),
    //       );
    //       rentalIncome = rentalIncome || this.defaults.rentalIncome;
    //     } else {
    //       rentalIncome = {
    //         rate: this.defaults.rentalIncome,
    //       };
    //     }
    //     if (rates.rentalExpense) {
    //       rentalExpense = rates.rentalExpense.rates.find(
    //         (rate) => parseInt(rate.date, 10) === parseInt(year, 10),
    //       );
    //       rentalExpense = rentalExpense || this.defaults.rentalExpense;
    //     } else {
    //       rentalExpense = {
    //         rate: this.defaults.rentalExpense,
    //       };
    //     }
    //     if (rates.taxableIncome) {
    //       taxableIncome = rates.taxableIncome.rates.find(
    //         (rate) => parseInt(rate.date, 10) === parseInt(year, 10),
    //       );
    //       taxableIncome = taxableIncome || this.defaults.taxableIncome;
    //     } else {
    //       taxableIncome = {
    //         rate: this.defaults.taxableIncome,
    //       };
    //     }
    //     if (rates.livingExpense) {
    //       livingExpense = rates.livingExpense.rates.find(
    //         (rate) => parseInt(rate.date, 10) === parseInt(year, 10),
    //       );
    //       livingExpense = livingExpense || this.defaults.livingExpense;
    //     } else {
    //       livingExpense = {
    //         rate: this.defaults.livingExpense,
    //       };
    //     }
    //     if (rates.capitalGrowth) {
    //       capitalGrowth = rates.capitalGrowth.rates.find(
    //         (rate) => parseInt(rate.date, 10) === parseInt(year, 10),
    //       );
    //       capitalGrowth = capitalGrowth || this.defaults.capitalGrowth;
    //     } else {
    //       capitalGrowth = {
    //         rate: this.defaults.capitalGrowth,
    //       };
    //     }
    //     yearArr.push(year);
    //     cpiArr.push(parseFloat(cpi).toFixed(2));
    //     rentalIncomeArr.push(parseFloat((rates.rentalIncome && rates.rentalIncome.sameAsCpi ? cpi : (rentalIncome ? rentalIncome.rate : this.defaults.rentalIncome.rate ?? 0))).toFixed(2));
    //     rentalExpenseArr.push(parseFloat((rates.rentalExpense && rates.rentalExpense.sameAsCpi ? cpi : (rentalExpense ? rentalExpense.rate : this.defaults.rentalExpense.rate ?? 0))).toFixed(2));
    //     taxableIncomeArr.push(parseFloat((rates.taxableIncome && rates.taxableIncome.sameAsCpi ? cpi : (taxableIncome ? taxableIncome.rate : this.defaults.taxableIncome.rate ?? 0))).toFixed(2));
    //     livingExpenseArr.push(parseFloat((rates.livingExpense && rates.livingExpense.sameAsCpi ? cpi : (livingExpense ? livingExpense.rate : this.defaults.livingExpense.rate ?? 0))).toFixed(2));
    //     capitalGrowthArr.push(parseFloat(capitalGrowth ? capitalGrowth.rate : this.defaults.capitalGrowth.rate ?? 0).toFixed(2));
    //   }
    //   data = {
    //     year: yearArr,
    //     rates: [
    //       {
    //         name: 'Inflation Rate (CPI)',
    //         id: 'cpi',
    //         rates: cpiArr,
    //       },
    //       {
    //         name: 'Rental Income Rate',
    //         same: true,
    //         id: 'rentalIncome',
    //         rates: rentalIncomeArr,
    //       },
    //       {
    //         name: 'Rental Expense Rate',
    //         same: true,
    //         id: 'rentalExpense',
    //         rates: rentalExpenseArr,
    //       },
    //       {
    //         name: 'Taxable Income Rate',
    //         same: true,
    //         id: 'taxableIncome',
    //         rates: taxableIncomeArr,
    //       },
    //       {
    //         name: 'Living Expense Rate',
    //         same: true,
    //         id: 'livingExpense',
    //         rates: livingExpenseArr,
    //       },
    //       {
    //         name: 'Capital Growth Rate',
    //         id: 'capitalGrowth',
    //         rates: capitalGrowthArr,
    //       },
    //     ],
    //   };
    //   return data;
    // },
  },
  methods: {
    sameAsCpi(event) {
      this.$store.dispatch('investorSameAsCpi', {
        name: event.target.name,
        value: event.target.checked,
        id: this.investor.id,
      });
    },
    rateChange(event) {
      console.log(parseFloat(event.target.value).toFixed(2));
      this.$store.dispatch('investorRateChange', {
        name: event.target.name,
        value: parseFloat(event.target.value).toFixed(2),
        id: this.investor.id,
      });
    },
  },
};
</script>
