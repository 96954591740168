<template>
    <article class="investor">
      <header>
        <div class="title">
          <h2>
            <router-link
              class="investorAvatar full"
              :to="{ name: 'InvestorView',
              params: { investorId: investor.id }}">
                <img :src=investor.avatar.image alt="">
                {{ investor.name }}
            </router-link>
          </h2>
          <ul class="stats">
            <li>{{investor.city}}, {{investor.state}}</li>
            <li><i class="icon solid fa-home"></i>{{ propertyCount }}</li>
          </ul>
        </div>
        <div class="meta">
          <ul class="actions stacked fit right">
            <li><router-link
              class="button fit"
              :to="{ name: 'InvestorView',
              params: { investorId: investor.id }}">View Investor</router-link></li>
            <li><router-link
              class="button fit"
              :to="{ name: 'InvestorEditView',
              params: { investorId: investor.id }}">
                Edit Investor
            </router-link></li>
          </ul>
        </div>
      </header>
    </article>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Investor',
  props: {
    investor: Object,
  },
  computed: {
    createDate() {
      return moment(this.investor.createDate.seconds * 1000).format('MMMM Do, YYYY');
    },
    propertyCount() {
      return this.$store.getters.properties(this.investor.id).length;
    },
  },
};
</script>
