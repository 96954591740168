<template>
  <section id="intro">
    <div class="dropzone" v-bind="getRootProps()">
      <input v-bind="getInputProps()">
      <div class="investorAvatar">
        <img :src=property.avatar.image :alt=property.address />
        <span class='overlay'><i class="icon solid fa-camera"></i></span>
      </div>
    </div>
    <header>
    <h2>{{property.address}}</h2>
    <p>{{property.city}} {{property.state}} {{property.postcode}}</p>
    <ul class="stats">
      <li>${{ (parseInt(property.finance.purchase.land, 10) + parseInt(property.finance.purchase.building, 10)).toLocaleString() }}</li>
      <li><i class="icon solid fa-bed" />{{property.bedrooms}}</li>
      <li><i class="icon solid fa-bath" />{{property.bathrooms}}</li>
      <li><i class="icon solid fa-car" />{{property.carSpaces}}</li>
    </ul>
    <ul class="actions fit stacked">
      <li>
        <router-link
          class="button fit icon solid fa-home"
          :to="{ name: 'PropertyEditView',
          params: { propertyId: property.id }}">
            Edit Property
        </router-link>
      </li>
      <li><a class='button fit icon fa-file solid' @click.prevent="report(false)" gtm-id='summary' >Summary Report</a></li>
      <li><a class='button fit icon fa-file-alt solid' @click.prevent="report(true)" gtm-id='details' >Detailed Report</a></li>
    </ul>
    </header>
  </section>
  <section class="blurb">
    <h3>Description/Notes</h3>
    <p>{{property.description}}</p>
  </section>
  <section class="blurb meta">
    <h3>Owner{{ property.owners.length > 1 ? 's' : '' }}</h3>
    <div v-for="owner in property.ownership" :key=owner.key class="author">
      <router-link
          class="author-link"
          :to="{ name: 'PropertyEditView',
          params: { propertyId: property.id }}">
          <span>&nbsp;({{ owner.percentage }}%)</span>
      </router-link>
      <router-link
        class="author-link"
        :to="{ name: 'InvestorView',
        params: { investorId: owner.key }}">
        <span class="name">{{ owner.investor.name }}</span><img :src=owner.investor.avatar.image :alt=owner.investor.name />
      </router-link>
    </div>
  </section>
  <!-- <section class="blurb">
    <h2>Reports</h2>
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Report</th>
            <th>Date</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Detailed</td>
            <td>October 2nd, 2021</td>
            <td><a class=" button no-label icon solid fa-download" /></td>
          </tr>
          <tr>
            <td>Summary</td>
            <td>October 14th, 2021</td>
            <td><a class="button no-label icon solid fa-download" /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section> -->
</template>

<script>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useDropzone } from 'vue3-dropzone';
import axios from 'axios';

export default {
  name: 'PropertyDetails',
  setup() {
    const store = useStore();
    const route = useRoute();
    const saveFiles = (files) => {
      store.commit('uploading', true);
      store.dispatch('editAvatar', {
        files,
        id: route.params.propertyId,
        type: 'property',
      });
    };

    function onDrop(acceptFiles, rejectReasons) {
      saveFiles(acceptFiles);
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  computed: {
    property() {
      const property = this.$store.getters.getProperty(this.$route.params.propertyId);
      return property;
    },
    investor() {
      return this.$store.getters.getInvestor(this.$route.params.investorId);
    },
    account() {
      return this.$store.state.account;
    },
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    const property = this.$store.getters.getProperty(this.$route.params.propertyId);
    if (typeof property.ownership === 'undefined') {
      const ownership = property.owners.map(
        (ownerId) => {
          const owner = this.$store.getters.getInvestor(ownerId);
          return {
            key: owner.id,
            investor: owner,
            percentage: 100 / property.owners.length,
          };
        },
      );
      this.$store.dispatch('editProperty', {
        name: 'ownership',
        value: ownership,
        id: property.id,
      });
    }
  },
  methods: {
    report(details) {
      let SERVER_URL = 'https://server.augurinvestor.com.au/';
      let type = 'Summary';
      const {
        $store,
        user,
        property,
        investor,
        account,
      } = this;
      if (details) {
        SERVER_URL = 'https://server.augurinvestor.com.au/details.php';
        type = 'Detailed';
      }
      $store.dispatch('generateReport')
        .then((id) => {
          const data = {
            id,
            type,
            user,
            property,
            investor,
            account,
            ...$store.state.spreadsheet,
          };
          axios.post(SERVER_URL, JSON.stringify(data))
            .then(() => {
              const url = `https://server.augurinvestor.com.au/pdf/${data.investor.name} - ${data.property.address} - ${data.type} ${id}.pdf`;
              window.open(url, '_blank');
            });
        });
    },
  },
};
</script>
