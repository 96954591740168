<template>
  <template v-if="isLoggedIn" >
    <section id="sidebar">
      <section id="intro">
        <header>
          <h2>Welcome to<br />Augur Investor</h2>
        </header>
        <p>Augur Investor is a property investment analysis tool essential for consultants wanting
          to manage their clients' investment property portfolios. Augur will analyse capital
          growth, cash flow, and tax implications for all of your investors' properties and
          provide projected after-tax costs and rates of return for every property in their
          portfolio.</p>
      </section>
    </section>
    <div id="main">
      <div class="form" v-if="subscription.toLowerCase() === 'professional' || access">
        <input type="text" name="search" v-model="search" placeholder="Search Investors..." />
      </div>
      <ul v-if="(subscription.toLowerCase() === 'professional' && investors.length > 0 || subscription.toLowerCase() === 'personal' && investors.length > 0 && investors.length < 2) || access" class='actions fit'>
        <li>
          <router-link
            class="button fit icon fa-user solid"
            :to="{ name: 'InvestorAddView' }">
              Add Investor
          </router-link>
        </li>
      </ul>
      <article v-if="investors.length === 0" class="investor">
        <header>
          <div class="title">
            <h2>
              No Investors Yet
            </h2>
            <p>Add a new investor to get started</p>
            <ul class="actions stacked">
              <li><router-link
                class="button icon fa-user solid"
                :to="{ name: 'InvestorAddView' }">
                  Add Investor
              </router-link></li>
            </ul>
          </div>
        </header>
      </article>
      <div v-for="investor in filteredInvestors" :key="investor.id">
        <Investor :investor=investor />
      </div>
      <article v-if="(subscription.toLowerCase() === 'professional' && investors.length > 0 || subscription.toLowerCase() === 'personal' && investors.length > 0 && investors.length < 2) || access" class="investor">
        <header>
          <div class="title">
            <h2>
              Add Investor
            </h2>
            <ul class="actions stacked">
              <li><router-link
                class="button icon fa-user solid"
                :to="{ name: 'InvestorAddView' }">
                  Add Investor
              </router-link></li>
            </ul>
          </div>
        </header>
      </article>
      <article v-if="investors.length > 2 && subscription.toLowerCase() === 'personal' && !access" class="investor">
        <header>
          <div class="title">
            <h2>
              Maximum Investors Reached
            </h2>
            <p>An Augur Investor personal plan allows for a two investor profiles. Upgrade to a professional plan for $399 per year to unlock unlimited investors.</p>
          </div>
          <div class="meta">
            <ul class="actions stacked fit right">
              <li><router-link
                class="button fit"
                :to="{ name: 'SubscriptionView'}">Upgrade</router-link>
              </li>
            </ul>
          </div>
        </header>
      </article>
    </div>
  </template>
  <LoginView v-else />
</template>

<script>
import Investor from '@/components/Investor.vue';
import LoginView from './LoginView.vue';

export default {
  name: 'HomeView',
  components: {
    Investor,
    LoginView,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    investors() {
      return this.$store.getters.investors;
    },
    subscription() {
      return this.$store.state.account.subscription;
    },
    access() {
      return this.$store.state.user.access === 'admin';
    },
    filteredInvestors() {
      if (this.search.length) {
        return this.investors.filter((investor) => investor.name.toLowerCase().includes(this.search.toLowerCase()));
      }
      return this.investors;
    },
  },
};
</script>
