// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAuoPZQncE7TgxiHk_shgZNatSuBaD6nEk',
  authDomain: 'augur-investor.firebaseapp.com',
  projectId: 'augur-investor',
  storageBucket: 'augur-investor.appspot.com',
  messagingSenderId: '526773232855',
  appId: '1:526773232855:web:370c9be9c4b3e724fbfc92',
  measurementId: 'G-4K0EB00VXG',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();

const usersCollection = db.collection('users');
const investorsCollection = db.collection('investors');
const propertiesCollection = db.collection('properties');
const accountsCollection = db.collection('accounts');
const reportsCollection = db.collection('reports');
const mailCollection = db.collection('mail');
const resetCollection = db.collection('reset');

export {
  auth,
  db,
  usersCollection,
  investorsCollection,
  propertiesCollection,
  accountsCollection,
  reportsCollection,
  mailCollection,
  resetCollection,
};
