<template>
  <div id="main">
    <article class="post">
      <header>
        <div class="title">
          <h2>Organisation Details {{ account.subscription !== 'personal' ? ' - ' + account.name.toString() : '' }}</h2>
          <p>Modify organistion details including name and theme.</p>
        </div>
      </header>
      <form class="form">
        <div class="row gtr-uniform">
          <div class="col-12">
            <h3>Theme</h3>
            <p v-if=personal>Custom themes are not available on personal plans. <router-link
                :to="{ name: 'SubscriptionView' }">Click here</router-link> to view Augur Investor subscription options.
            </p>
          </div>
          <div class="col-6">
            <label>Organisation Name</label>
            <div>
              <input type="text" :disabled="personal || !(user.access === 'admin')" name="name" :value=account.name
                placeholder="Organisation Name" @change.prevent=editAccount />
            </div>
          </div>
          <div class="col-12">
            <label>Disclaimer</label>
            <div>
              <textArea rows="4" :disabled="personal || !(user.access === 'admin')" name="disclaimer"
                :value=account.disclaimer placeholder="Disclaimer" @change.prevent=editAccount />
            </div>
          </div>
          <div class="col-12">
            <h4>Colours</h4>
          </div>
          <div class="col-3 row">
            <div class="col-12">
              <label>Header Colour</label>
            </div>
            <div class="col-3">
              <input type="color" name="colour" :disabled="personal || !(user.access === 'admin')" :value=account.colour @change.prevent=editAccount />
            </div>
            <div class="col-9">
              <input type="text" name="colour" :disabled="personal || !(user.access === 'admin')" :value=account.colour
                placeholder="Header Colour" @change.prevent=editAccount />
            </div>
          </div>
          <div class="col-3 row">
            <div class="col-12">
              <label>Header Text Colour</label>
            </div>
            <div class="col-3">
              <input type="color" name="headerTextColour" :disabled="personal || !(user.access === 'admin')" :value=account.headerTextColour @change.prevent=editAccount />
            </div>
            <div class="col-9">
              <input type="text" name="headerTextColour" :disabled="personal || !(user.access === 'admin')" :value=account.headerTextColour
                placeholder="Header Text Colour" @change.prevent=editAccount />
            </div>
          </div>
          <div class="col-3 row">
            <div class="col-12">
              <label>Footer Colour</label>
            </div>
            <div class="col-3">
              <input type="color" name="footerColour" :disabled="personal || !(user.access === 'admin')" :value=account.footerColour @change.prevent=editAccount />
            </div>
            <div class="col-9">
              <input type="text" name="footerColour" :disabled="personal || !(user.access === 'admin')" :value=account.footerColour
                placeholder="Footer Colour" @change.prevent=editAccount />
            </div>
          </div>
          <div class="col-3 row">
            <div class="col-12">
              <label>Footer Text Colour</label>
            </div>
            <div class="col-3">
              <input type="color" name="footerTextColour" :disabled="personal || !(user.access === 'admin')" :value=account.footerTextColour @change.prevent=editAccount />
            </div>
            <div class="col-9">
              <input type="text" name="footerTextColour" :disabled="personal || !(user.access === 'admin')" :value=account.footerTextColour
                placeholder="Footer Text Colour" @change.prevent=editAccount />
            </div>
          </div>
          <div class="col-3 row">
            <div class="col-12">
              <label>Page Colour</label>
            </div>
            <div class="col-3">
              <input type="color" name="pageColour" :disabled="personal || !(user.access === 'admin')" :value=account.pageColour @change.prevent=editAccount />
            </div>
            <div class="col-9">
              <input type="text" name="pageColour" :disabled="personal || !(user.access === 'admin')" :value=account.pageColour
                placeholder="Footer Text Colour" @change.prevent=editAccount />
            </div>
          </div>
        </div>
      </form>
    </article>
    <article class="post">
      <header>
        <div class="title">
          <h2>Users</h2>
          <p>Modify organistion users.</p>
        </div>
      </header>
      <p v-if=personal>Multiple users are not available on personal plans. <router-link
          :to="{ name: 'SubscriptionView' }">Click here</router-link> to view Augur Investor subscription options.</p>
      <div v-else id="rates-table" class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th class="align-center">Admin</th>
              <th v-if="user.access === 'admin'" class="align-center">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in account.users" :key="row.id">
              <td>{{ row.name }}</td>
              <td>{{ row.email }}</td>
              <td class="align-center">
                <input :disabled="user.access !== 'admin' || row.id === user.id" type="checkbox" :value=row.id :id=row.id
                  :name=row.id :checked="row.access === 'admin'" @change.prevent=toggleAdmin($event)>
                <label :for=row.id></label>
              </td>
              <td v-if="user.access === 'admin' && user.id !== row.id">
                <router-link class="button fit" :to="{
                  name: 'EditUserView',
                  params: { userId: row.id }
                }">
                  User Details
                </router-link>
              </td>
              <td v-if="user.id === row.id">
                <router-link class="button fit" :to="{ name: 'UserView' }">
                  Personal Details
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OrganisationView',
  computed: {
    ...mapState([
      'user',
      'account',
    ]),
    personal() {
      return this.account.subscription === 'personal';
    },
  },
  methods: {
    editAccount(event) {
      this.$store.dispatch('editAccount', {
        name: event.target.name,
        value: event.target.value,
        id: this.account.id,
      });
    },
    toggleAdmin(event) {
      this.$store.dispatch('toggleAdmin', {
        status: (event.target.checked ? 'admin' : 'standard'),
        id: event.target.value,
      });
    },
  },
};
</script>
