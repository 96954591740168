<template>
  <section id="sidebar">
    <div>
      <div v-bind="getRootProps()">
        <input v-bind="getInputProps()">
        <div class="avatar">
          <img :src=property.avatar.image alt="">
          <span class='overlay'><i class="icon solid fa-camera"></i></span>
        </div>
      </div>
    </div>
  </section>
  <div id="main">
    <article class="post">
      <header>
        <div class="title">
          <h2>{{ property.address }}</h2>
          <p>{{property.city}} {{property.state}} {{property.postcode}}</p>
        </div>
        <div class="meta">
          <ul class="actions stacked fit right">
            <li v-if="!deleted && !move && !copy">
              <a class="button fit icon solid fa-trash" @click.prevent="toggleDelete">Delete</a>
            </li>
            <li v-if="!deleted && !move && !copy">
              <a class="button fit icon solid fa-copy" @click.prevent="toggleCopy">Copy</a>
            </li>
            <li v-if="!deleted && !move && !copy">
              <a class="button fit icon solid fa-exchange-alt" @click.prevent="toggleMove">Move</a>
            </li>
            <li v-if="deleted || move || copy">
              <a class="button fit solid" @click.prevent="cancel">Cancel</a>
            </li>
          </ul>
          <p v-if="deleted">This property will be deleted. This cannot be undone.</p>
          <p v-if="move">This property will be moved to the chosen investor. All other owners will be removed.</p>
          <p v-if="copy">This property will be duplicated with the same owners.</p>
          <div class="form">
            <select v-if="move" name="moveOwner" placeholder="moveOwner" v-model="newOwner">
              <option v-for="investor in otherInvestors" :key=investor.id :value=investor.id >{{ investor.name }}</option>
            </select>
          </div>
          <ul class="actions stacked fit right">
            <li v-if="deleted">
              <a class="button fit icon solid fa-trash" @click.prevent="deleteProperty">Delete</a>
            </li>
            <li v-if="move">
              <a class="button fit icon solid fa-exchange-alt" @click.prevent="moveProperty">Move</a>
            </li>
            <li v-if="copy">
              <a class="button fit icon solid fa-copy" @click.prevent="copyProperty">Copy</a>
            </li>
          </ul>
        </div>
      </header>
      <form class="form" >
        <div class="row gtr-uniform">
          <div class="col-12">
            <h3>Address Details</h3>
          </div>
          <div class="col-12">
            <input type="text" name="address" :value=property.address placeholder="Address" @change.prevent=editProperty />
          </div>
          <div class="col-6 col-12-xsmall">
            <input type="text" name="city" :value=property.city placeholder="City" @change.prevent=editProperty />
          </div>
          <div class="col-3 col-6-xsmall">
            <select name="state" required :value=property.state @change.prevent=editProperty >
              <option value="VIC">VIC</option>
              <option value="NSW">NSW</option>
              <option value="WA">WA</option>
              <option value="QLD">QLD</option>
              <option value="TAS">TAS</option>
              <option value="SA">SA</option>
              <option value="ACT">ACT</option>
              <option value="NT">NT</option>
            </select>
          </div>
          <div class="col-3 col-6-xsmall">
            <input type="text" name="postcode" :value=property.postcode
              placeholder="Postcode" @change.prevent=editProperty />
          </div>
          <div class="col-12">
            <h3>Property Details</h3>
          </div>
          <div class="col-4 col-12-xsmall">
            <label>Bedrooms</label>
            <select name="bedrooms" :value=property.bedrooms @change.prevent=editProperty placeholder="Bedrooms">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div class="col-4 col-12-xsmall">
            <label>Bathrooms</label>
            <select name="bathrooms" :value=property.bathrooms @change.prevent=editProperty placeholder="Bathrooms">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div class="col-4 col-12-xsmall">
            <label>Car Spaces</label>
            <select name="carSpaces" :value=property.carSpaces @change.prevent=editProperty placeholder="Car Spaces">
              <option value="0">No Car Spaces</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div class="col-12">
            <textarea name="description" :value=property.description @change.prevent=editProperty placeholder="Description/Notes"></textarea>
          </div>
          <div class="col-12">
            <h3>Ownership</h3>
          </div>
          <div class="col-6"><h4>Owner</h4></div>
          <div class="col-4"><h4>Ownership percentage</h4></div>
          <template :key=index v-for="(owner, index) in property.ownership">
            <template v-if="owner.key == investor.id">
              <div class="col-6">
                <input disabled type="text" name="initialowner-{{owner.key}}" :value=owner.investor.name />
              </div>
              <div class="col-4">
                <input disabled type="text" name="initialowner-percentage-{{owner.key}}" :value=owner.percentage /> <i class="icon solid fa-percentage input" />
              </div>
            </template>
            <template v-else>
              <div class="col-6">
                <select name="additionalOwner" placeholder="Additional Owner" v-model="owner.investor.id" @change.prevent="updateOwner($event, index)">
                  <option v-for="investor in investors" :key=investor.id :value=investor.id >{{ investor.name }}</option>
                </select>
              </div>
              <div class="col-4">
                <input type="text" name="initialowner-percentage-{{owner.key}}" @change.prevent="updateOwnership($event, index)" :value=parseInt(owner.percentage,10) /> <i class="icon solid fa-percentage input" />
              </div>
              <div class="col-2">
                <ul class="actions right">
                  <li>
                    <a class="button fit icon solid fa-trash" @click="deleteOwner($event, index)" >Delete</a>
                  </li>
                </ul>
              </div>
            </template>
          </template>
        </div>
      </form>
      <ul class="actions right">
        <li>
          <router-link
            :class=uploading
            class="button fit"
            :to="{ name: 'PropertyView',
            params: { propertyId: property.id }}">
              Done
          </router-link>
        </li>
        <li>
          <a class="button fit icon solid fa-plus" @click.prevent=addOwner >Add additional Owner</a>
        </li>
      </ul>
    </article>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useDropzone } from 'vue3-dropzone';

export default {
  name: 'PropertyEditView',
  setup() {
    const store = useStore();
    const route = useRoute();
    const saveFiles = (files) => {
      store.commit('uploading', true);
      store.dispatch('editAvatar', {
        files,
        id: route.params.propertyId,
        type: 'property',
      });
    };

    function onDrop(acceptFiles, rejectReasons) {
      saveFiles(acceptFiles);
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  data() {
    return {
      deleted: false,
      move: false,
      copy: false,
      newOwner: null,
    };
  },
  computed: {
    uploading() {
      return (this.$store.state.uploading ? 'disabled' : '');
    },
    property() {
      const prop = this.$store.getters.getProperty(this.$route.params.propertyId);
      return prop;
    },
    investors() {
      const otherOwners = this.$store.getters.investors.filter((element) => this.investor.id !== element.id);
      return otherOwners;
    },
    otherInvestors() {
      const otherOwners = this.$store.getters.investors.filter((element) => !this.property.owners.includes(element.id));
      return otherOwners;
    },
    investor() {
      return this.$store.getters.getInvestor(this.$route.params.investorId);
    },
  },
  methods: {
    dispatchOwners() {
      this.$store.dispatch('editProperty', {
        name: 'owners',
        value: this.property.owners,
        id: this.property.id,
      });
      this.$store.dispatch('editProperty', {
        name: 'ownership',
        value: this.property.ownership,
        id: this.property.id,
      });
    },
    updateOwner(event, index) {
      this.property.ownership[index] = {
        ...this.property.ownership[index],
        key: event.target.value,
        investor: this.$store.getters.getInvestor(event.target.value),
      };
      this.property.owners[index] = event.target.value;
      this.dispatchOwners();
    },
    deleteOwner(event, index) {
      this.property.ownership.splice(index, 1);
      this.property.owners.splice(index, 1);
      this.dispatchOwners();
    },
    updateOwnership(event, index) {
      this.property.ownership[index] = {
        ...this.property.ownership[index],
        percentage: parseInt(event.target.value, 10),
      };
      let totalPercentage = 0;
      this.property.ownership.forEach((item, i) => {
        if (i > 0) {
          totalPercentage += parseInt(item.percentage, 10);
        }
      });
      this.property.ownership[0] = {
        ...this.property.ownership[0],
        percentage: 100 - totalPercentage,
      };
      this.dispatchOwners();
    },
    editProperty(event) {
      this.$store.dispatch('editProperty', {
        name: event.target.name,
        value: event.target.value,
        id: this.property.id,
      });
    },
    addOwner() {
      this.property.ownership.push(
        {
          key: 'new',
          investor: '',
          percentage: 0,
        },
      );
      this.property.owners.push('new');
    },
    toggleDelete() {
      this.deleted = !this.deleted;
    },
    toggleMove() {
      this.move = !this.move;
    },
    toggleCopy() {
      this.copy = !this.copy;
    },
    cancel() {
      this.copy = false;
      this.move = false;
      this.deleted = false;
    },
    deleteProperty() {
      this.$store.dispatch('deleteProperty', this.property.id);
      this.$router.push({ name: 'InvestorView', params: { investorId: this.investor.id } });
    },
    copyProperty() {
      this.$store.dispatch('copyProperty', this.property);
    },
    moveProperty() {
      const payload = { property: this.property.id, new: this.newOwner };
      this.$store.dispatch('moveProperty', payload);
    },
  },
};
</script>
