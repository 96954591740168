<template>
  <article class="post">
    <header>
      <div class="title">
        <h2>Finance Details</h2>
      </div>
    </header>
    <form class="form" >
      <div class="row gtr-uniform">
        <div class="col-12">
          <h3>Loan</h3>
          <p>A loan may be principle and Interest, meaning the total of the loan amount is being paid down over time, or Interest Only,
            meaning only the interest accrued in each month is being paid and the loan amount remains the same. A principle and interest
            loan increases the monthly repayment, but the loan amount and interest paid will reduce over time. Interest can
            be claimed as a cash deduction.</p>
        </div>
        <div class="col-3 col-6-medium">
          <label>Loan Type</label>
          <div>
            <select name="type" :value=property.finance.loan.type @change.prevent="editPropertyText('loan', $event)">
              <option value="io" >Interest Only</option>
              <option value="pi" >Principle and Interest</option>
            </select>
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Deposit</label>
          <div>
            <input type="text" class="left" name="investment" :value=parseInt(property.finance.loan.investment,10).toLocaleString() placeholder="Deposit"  @change.prevent="editDeposit($event)"  /> <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Interest Rate</label>
          <div>
            <input type="text" class="right" name="interestRate" :value=parseFloat(property.finance.loan.interestRate).toFixed(2) placeholder="Interest Rate"  @change.prevent="editProperty('loan', $event)"  /> <i class="icon solid fa-percentage input right" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Monthly Repayment</label>
          <div>
            <input type="text" class="left" name="repayment" disabled
              :value=parseInt(property.finance.loan.repayment,10).toLocaleString()
              placeholder="Repayment" >
            <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-12">
          <hr />
          <h3>Borrowing Costs</h3>
          <p>These are the costs associated with establishing a new home loan.</p>
        </div>
        <div class="col-3 col-6-medium">
          <label>Establishment Fee</label>
          <div>
            <input type="text" class="left" name="establishment"  :value=parseInt(property.finance.loanCosts.establishment,10).toLocaleString() @change.prevent="editProperty('loanCosts', $event)" /> <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Mortgage Insurance</label>
          <div>
            <input type="text" class="left" name="insurance" disabled :value=parseInt(property.finance.loanCosts.insurance,10).toLocaleString() @change.prevent="editProperty('loanCosts', $event)" /> <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Valuation Fees</label>
          <div>
            <input type="text" class="left" name="valuation"  :value=parseInt(property.finance.loanCosts.valuation,10).toLocaleString() @change.prevent="editProperty('loanCosts', $event)" /> <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Registration Fees</label>
          <div>
            <input type="text" class="left" name="registration"  :value=parseInt(property.finance.loanCosts.registration,10).toLocaleString() @change.prevent="editProperty('loanCosts', $event)" /> <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Solicitor's Fees</label>
          <div>
            <input type="text" class="left" name="solicitors"  :value=parseInt(property.finance.loanCosts.solicitors,10).toLocaleString() @change.prevent="editProperty('loanCosts', $event)" /> <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-3 col-6-medium">
          <label>Search Fees</label>
          <div>
            <input type="text" class="left" name="search"  :value=parseInt(property.finance.loanCosts.search,10).toLocaleString() @change.prevent="editProperty('loanCosts', $event)" /> <i class="icon solid fa-dollar-sign input left" />
          </div>
        </div>
        <div class="col-12">
          <hr />
          <h3>Borrowing Cost Write-off</h3>
          <p>Borrowing costs, while not capitalised onto the loan, can be written off as a non-cash deduction.</p>
        </div>
        <div class="col-3 col-6-medium">
          <label>Years</label>
          <div>
            <input type="text" class="" name="writeOff"  :value=parseInt(property.finance.loan.writeOff,10) @change.prevent="editProperty('loan', $event)" />
          </div>
        </div>
      </div>
    </form>
  </article>
</template>

<script>
export default {
  name: 'PropertyFinanceDetails',
  computed: {
    property() {
      return this.$store.getters.getProperty(this.$route.params.propertyId);
    },
  },
  methods: {
    propertyValue() {
      let value;
      if (this.property.finance.purchase.type === 'existing') {
        value = (parseInt(this.property.finance.purchase.land.toString().replace(/[^0-9.]/g, ''), 10) || 0) + (parseInt(this.property.finance.purchase.building.toString().replace(/[^0-9.]/g, ''), 10) || 0);
      } else if (this.property.finance.purchase.type === 'new') {
        value = parseInt(this.property.finance.purchase.land.toString().replace(/[^0-9.]/g, ''), 10) || 0;
      }
      this.$store.dispatch('editPurchaseCosts', { property: this.property, value });
      return value ?? 0;
    },
    editProperty(type, event) {
      this.$store.dispatch('editPropertyFinance', {
        type,
        name: event.target.name,
        value: event.target.value.toString().replace(/[^0-9.]/g, '') || 0,
        id: this.property.id,
      })
        .then(() => {
          this.propertyValue();
        });
    },
    editPropertyText(type, event) {
      this.$store.dispatch('editPropertyFinance', {
        type,
        name: event.target.name,
        value: event.target.value.toString(),
        id: this.property.id,
      })
        .then(() => {
          this.propertyValue();
        });
    },
    editDeposit(event) {
      let value;
      if (event.target.value > (parseInt(this.property.finance.purchase.building, 10) + parseInt(this.property.finance.purchase.land, 10)) * 0.9) {
        value = ((parseInt(this.property.finance.purchase.building, 10) + parseInt(this.property.finance.purchase.land, 10)) * 0.9).toString().replace(/[^0-9.]/g, '') || 0;
      } else {
        value = event.target.value.toString().replace(/[^0-9.]/g, '') || 0;
      }
      this.$store.dispatch('editPropertyFinance', {
        type: 'loan',
        name: event.target.name,
        value: value.toLocaleString(),
        id: this.property.id,
      })
        .then(() => {
          this.propertyValue();
        });
    },
  },
};
</script>
