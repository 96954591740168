<template>
  <section id="sidebar">
    <div>
      <div v-bind="getRootProps()">
        <input v-bind="getInputProps()">
          <div class="avatar">
            <img :src=avatar.image alt="">
            <span class='overlay'><i class="icon solid fa-camera"></i></span>
          </div>
      </div>
    </div>
  </section>
  <div id="main">
    <article class="post">
      <header>
        <div class="title">
          <h2>New Investor</h2>
        </div>
      </header>
      <form class="form" >
        <div class="row gtr-uniform">
          <div class="col-12">
            <input placeholder="Name" name="name" type="text"
              v-model=investor.name />
          </div>
          <div class="col-12">
            <h3>Contact Details</h3>
          </div>
          <div class="col-12">
            <input type="text" name="phone" v-model=investor.phone placeholder="Phone" />
          </div>
          <div class="col-12">
            <input type="text" name="email" v-model=investor.email placeholder="Email" />
          </div>
          <div class="col-12">
            <input type="text" name="address" v-model=investor.address placeholder="Address" />
          </div>
          <div class="col-6">
            <input type="text" name="city" v-model=investor.city placeholder="City" />
          </div>
          <div class="col-3">
            <select name="state" v-model=investor.state >
              <option value="VIC">VIC</option>
              <option value="NSW">NSW</option>
              <option value="WA">WA</option>
              <option value="QLD">QLD</option>
              <option value="TAS">TAS</option>
              <option value="SA">SA</option>
              <option value="ACT">ACT</option>
              <option value="NT">NT</option>
            </select>
          </div>
          <div class="col-3">
            <input type="text" name="postcode" v-model=investor.postcode
              placeholder="Postcode" />
          </div>
        </div>
      </form>
      <ul class="actions right">
        <li>
          <a :class=uploading class="button fit icon solid fa-save" @click=addInvestor >Save</a>
        </li>
      </ul>
    </article>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useDropzone } from 'vue3-dropzone';

export default {
  name: 'InvestorAddView',
  setup() {
    const store = useStore();
    const saveFiles = (files) => {
      store.commit('uploading', true);
      store.dispatch('temporaryAvatar', {
        files,
        type: 'investor',
      });
    };

    function onDrop(acceptFiles, rejectReasons) {
      saveFiles(acceptFiles);
      if (rejectReasons) {
        console.log(rejectReasons);
      }
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  data() {
    return {
      investor: {
        name: '',
        type: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        postcode: '',
      },
    };
  },
  computed: {
    uploading() {
      return (this.$store.state.uploading ? 'disabled' : '');
    },
    user() {
      return this.$store.state.user.id;
    },
    avatar() {
      if (this.$store.state.temporaryAvatar) {
        return this.$store.state.temporaryAvatar;
      }
      return { image: '/images/avatar.svg' };
    },
  },
  methods: {
    addInvestor() {
      this.$store.dispatch('addInvestor', { ...this.investor, avatar: this.avatar, user: this.user });
      this.$router.push({ name: 'HomeView' });
    },
  },
  watch: {
    $route() {
      this.$store.commit('clearTemporaryAvatar');
    },
  },
};
</script>
