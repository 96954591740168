<template>
  <div id="main">
    <article class="post">
      <header>
        <div class="title">
          <h2>Subscription Details - {{ account.name }}</h2>
          <p>Modify subscription details including plan and billing details.</p>
        </div>
      </header>
      <h3>Current Subscription: {{account.subscription}}</h3>
      <template v-if="account.subscription === 'Professional'">
        <p>Please <a href="mailto:support@augurinvestor.com.au" target="_blank" >email our support team</a> to add or remove users from your subscription.</p>
      </template>
      <template v-else>
        <p>Please <a href="mailto:support@augurinvestor.com.au" target="_blank" >email our support team</a> to upgrade or change your subscription term.</p>
        <p>Check out our subscription options <a href="https://www.augurinvestor.com.au/pricing" target="_blank" >available here.</a></p>
      </template>
      <h3>Cancel Your Subscription</h3>
      <p>Please <a href="mailto:support@augurinvestor.com.au" target="_blank" >email our support team</a> to cancel your Augur Investor subscription.</p>
      <p>Your subscription will be cancelled at the end of your current commitment term, monthly or annually, regardless of billing frequency. You will continue to have access to Augur Investor until the end of your current commitment term.</p>
      <p>If your subscription is cancelled, Augur Investor will delete any investor or property data created by your account at the end of your current commitment term.</p>
    </article>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionView',
  computed: {
    user() {
      return this.$store.state.user;
    },
    account() {
      return this.$store.state.account;
    },
  },
};
</script>
