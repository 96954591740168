<template>
  <div class="form" v-if="properties.length > 1">
    <input type="text" name="search" v-model="search" placeholder="Search Properties..." />
  </div>
  <div v-for="property in filteredProperties" :key="property.id" >
    <Property :property=property />
  </div>
  <article v-if="properties.length === 0" class="investor">
    <header>
      <div class="title">
        <h2>
          No Properties Yet
        </h2>
        <p>Add a new property to get started</p>
        <ul class="actions stacked">
          <li><router-link
            class="button icon fa-home solid"
            :to="{ name: 'PropertyAddView' }">
              Add Property
          </router-link></li>
        </ul>
      </div>
    </header>
  </article>
  <article v-else class="investor">
    <header>
      <div class="title">
        <h2>
          Add property
        </h2>
        <p>Add a new property to this investor</p>
        <ul class="actions stacked">
          <li><router-link
            class="button icon fa-home solid"
            :to="{ name: 'PropertyAddView' }">
              Add Property
          </router-link></li>
        </ul>
      </div>
    </header>
  </article>
</template>

<script>
import Property from '@/components/Property.vue';

export default {
  name: 'InvestorView',
  components: {
    Property,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    properties() {
      return this.$store.getters.properties(this.$route.params.investorId);
    },
    filteredProperties() {
      if (this.search.length) {
        return this.properties.filter((property) => (`${property.address} ${property.city} ${property.state} ${property.postcode}`).toLowerCase().includes(this.search.toLowerCase()));
      }
      return this.properties;
    },
  },
};
</script>
