<template>
  <footer  v-show="active" class="panel">
    <i class="close icon solid fa-times" @click="about"></i>
    <div class="inner split">
      <div>
        <div class="logo"><img :src=logo alt=""></div>
        <section>
          <h2>Augur Investor<strong>&nbsp;{{ subscription }}</strong></h2>
          <p>Augur Investor is a property investment analysis tool essential for consultants
          wanting to manage their clients' investment property portfolios. Augur will analyse
          capital growth, cash flow, and tax implications for all of your investors' properties
          and provide projected after-tax costs and rates of return for every property in their
          portfolio.</p>
        </section>
        <section>
          <h2>Follow Us on ...</h2>
          <ul class="icons">
            <!-- <li>
              <a href="#" class="icon brands fa-twitter">
                <span class="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="#" class="icon brands fa-facebook-f">
                <span class="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="#" class="icon brands fa-instagram">
                <span class="label">Instagram</span>
              </a>
            </li> -->
            <li>
              <a href="https://www.linkedin.com/company/augur-investor" class="icon brands fa-linkedin-in">
                <span class="label">LinkedIn</span>
              </a>
            </li>
          </ul>
        </section>
        <p class="copyright">
        &copy; {{ thisYear }}. PM Asset Holdings.
        </p>
      </div>
      <div>
        <section>
          <h2>Get in touch</h2>
          <form id="contact-us-form" class="form">
            <div class="row gtr-uniform">
              <div class="col-6 col-12-xsmall">
                <input type="text" name="name" id="name" v-model="name" placeholder="Name" />
              </div>
              <div class="col-6 col-12-xsmall">
                <input  type="email"
                        name="email"
                        id="email"
                        v-model="email"
                        placeholder="Email" />
              </div>
              <div class="col-12">
                <textarea name="message"
                          id="message"
                          placeholder="Enter your message"
                          v-model="message"
                          rows="6"></textarea>
              </div>
              <div class="col-12">
                <ul class="actions">
                  <li><input type="submit" value="Send Message" @click.prevent="send"/></li>
                  <li><input type="reset" value="Reset" @click.prevent="reset" /></li>
                </ul>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </footer>
</template>

<script>
import logo from '@/images/Augur White Horizontal.svg';
import moment from 'moment';

export default {
  name: 'About',
  components: {
  },
  data() {
    return {
      logo,
      name: '',
      email: '',
      message: '',
    };
  },
  computed: {
    subscription() {
      return this.$store.state.account.subscription;
    },
    active() {
      return this.$store.state.aboutPanel;
    },
    thisYear() {
      return moment().format('YYYY');
    },
  },
  methods: {
    about() {
      this.$store.commit('about');
    },
    reset() {
      this.name = '';
      this.email = '';
      this.message = '';
    },
    send() {
      const form = document.getElementById('contact-us-form');
      const { name, email, message } = this;
      if (!form.checkValidity()) {
        form.reportValidity();
        return;
      }
      this.$store.dispatch('sendContactForm', { name, email, message });
    },
  },
  watch: {
    $route() {
      this.$store.commit('closeAbout');
    },
  },
};
</script>

<style lang="scss">

  .appear-enter-from {
    transform: translateY(100vh);
  }

  .appear-enter-active {
    transition: transform 1s ease;
  }

</style>
