<template>
  <footer id="footer">
    <h1>
      <span>Augur Investor<strong>&nbsp;{{ subscription }}</strong></span>
    </h1>
    <nav class="main">
      <ul>
        <li v-if="deferredPrompt">
          <a class="fa-download" @click="install">Install</a>
        </li>
        <li class="about">
          <a class="fa-info-circle" @click="about">About</a>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      deferredPrompt: null,
    };
  },
  computed: {
    subscription() {
      return this.$store.state.account.subscription;
    },
  },
  methods: {
    about() {
      this.$store.commit('about');
    },
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });

    window.addEventListener('appinstalled', () => {
      console.log('App is installed');
      this.deferredPrompt = null;
    });
  },
};
</script>
