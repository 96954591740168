<template>
  <section id="menu" v-show="active">
    <section>
      <h2>{{ user.name }}</h2>
    </section>
    <!-- Links -->
      <section>
        <i class="close icon solid fa-times" @click="menu"></i>
        <ul class="links">
          <li>
            <router-link
              :to="{ name: 'UserView'}">
              <h3>Personal Details</h3>
              <p>Name, email, password</p>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'DefaultsView'}">
                <h3>Defaults</h3>
                <p>Rates, costs</p>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'OrganisationView'}">
              <h3>Organisation Details</h3>
              <p>Name, theme, users</p>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'SubscriptionView'}">
              <h3>Subscription Details</h3>
              <p>Subscription, billing</p>
            </router-link>
          </li>
        </ul>
      </section>

    <!-- Actions -->
      <section>
        <ul class="actions stacked">
          <li><a @click.prevent=logout class="button large fit">Log Out</a></li>
        </ul>
      </section>

  </section>
</template>

<script>

export default {
  name: 'Menu',
  props: {},
  methods: {
    menu() {
      this.$store.commit('menu');
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'HomeView' });
    },
  },
  computed: {
    active() {
      return this.$store.state.menu;
    },
    user() {
      return this.$store.state.user;
    },
    account() {
      return this.$store.state.account;
    },
  },
  watch: {
    $route() {
      this.$store.commit('closeMenu');
    },
  },
};
</script>
