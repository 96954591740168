import { createApp } from 'vue';
import { createGtm } from 'vue-gtm';
import App from './App.vue';
import router from './router';
import { auth } from './firebase';
import store from './store';
import './registerServiceWorker';

let app;

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    app.use(router);
    app.use(store);
    app.use(
      createGtm({
        id: 'GTM-T4LSJNB',
        defer: false,
        compatibility: false,
        enabled: true,
        debug: true,
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: false,
      }),
    );
    app.config.productionTip = false;

    app.mount('#app');
  }
});
