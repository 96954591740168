import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import InvestorView from '../views/InvestorView.vue';
import InvestorEditView from '../views/InvestorEditView.vue';
import RatesView from '../views/RatesView.vue';
import DefaultsView from '../views/DefaultsView.vue';
import UserView from '../views/UserView.vue';
import EditUserView from '../views/EditUserView.vue';
import OrganisationView from '../views/OrganisationView.vue';
import SubscriptionView from '../views/SubscriptionView.vue';
import PropertyView from '../views/PropertyView.vue';
import InvestorAddView from '../views/InvestorAddView.vue';
import PropertyEditView from '../views/PropertyEditView.vue';
import PropertyAddView from '../views/PropertyAddView.vue';
import ForgotPasswordView from '../views/ForgotPasswordView.vue';
import UserCreate from '../views/UserCreate.vue';

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    meta: {
      title: 'Home',
      breadcrumb: [
        { name: 'Investors' },
      ],
    },
  },
  {
    path: '/api/user-create',
    name: 'UserCreate',
    component: UserCreate,
    props: true,
    meta: {
      title: 'Create User',
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordView',
    component: ForgotPasswordView,
    meta: {
      title: 'Forgot Password',
    },
  },
  {
    path: '/investor/add',
    name: 'InvestorAddView',
    component: InvestorAddView,
    meta: {
      title: 'Add Investor',
      breadcrumb: [
        { name: 'Investors', link: 'HomeView' },
        { name: 'Add Investor' },
      ],
    },
  },
  {
    path: '/investor/:investorId/edit',
    name: 'InvestorEditView',
    component: InvestorEditView,
    meta: {
      title: 'Edit Investor',
      breadcrumb: [
        { name: 'Investors', link: 'HomeView' },
        { investor: true },
        { name: 'Edit' },
      ],
    },
  },
  {
    path: '/investor/:investorId/rates',
    name: 'RatesView',
    component: RatesView,
    meta: {
      title: 'Investor Default Rates',
      breadcrumb: [
        { name: 'Investors', link: 'HomeView' },
        { investor: true },
        { name: 'Rates' },
      ],
    },
  },
  {
    path: '/investor/:investorId',
    name: 'InvestorView',
    component: InvestorView,
    meta: {
      title: 'Investor',
      breadcrumb: [
        { name: 'Investors', link: 'HomeView' },
        { investor: true },
      ],
    },
  },
  {
    path: '/investor/:investorId/property/:propertyId',
    name: 'PropertyView',
    component: PropertyView,
    meta: {
      title: 'Property',
      breadcrumb: [
        { name: 'Investors', link: 'HomeView' },
        { investor: true },
        { property: true },
      ],
    },
  },
  {
    path: '/investor/:investorId/property/:propertyId/edit',
    name: 'PropertyEditView',
    component: PropertyEditView,
    meta: {
      title: 'Edit Property',
      breadcrumb: [
        { name: 'Investors', link: 'HomeView' },
        { investor: true },
        { property: true },
        { name: 'Edit' },
      ],
    },
  },
  {
    path: '/investor/:investorId/property/add',
    name: 'PropertyAddView',
    component: PropertyAddView,
    meta: {
      title: 'Add Property',
      breadcrumb: [
        { name: 'Investors', link: 'HomeView' },
        { investor: true },
        { name: 'Add Property' },
      ],
    },
  },
  {
    path: '/settings/defaults',
    name: 'DefaultsView',
    component: DefaultsView,
    meta: {
      title: 'Default Values',
      breadcrumb: [
        { name: 'Home', link: 'HomeView' },
        { name: 'Personal Defaults' },
      ],
    },
  },
  {
    path: '/settings/user',
    name: 'UserView',
    component: UserView,
    meta: {
      title: 'Edit User',
      breadcrumb: [
        { name: 'Home', link: 'HomeView' },
        { name: 'Personal Details' },
      ],
    },
  },
  {
    path: '/settings/organisation/user/:userId',
    name: 'EditUserView',
    component: EditUserView,
    meta: {
      title: 'Edit Organisation User',
      breadcrumb: [
        { name: 'Home', link: 'HomeView' },
        { name: 'Organisation Details', link: 'OrganisationView' },
        { name: 'Edit User' },
      ],
    },
  },
  {
    path: '/settings/organisation',
    name: 'OrganisationView',
    component: OrganisationView,
    meta: {
      title: 'Your Organisation',
      breadcrumb: [
        { name: 'Home', link: 'HomeView' },
        { name: 'Organisation Details' },
      ],
    },
  },
  {
    path: '/settings/subscription',
    name: 'SubscriptionView',
    component: SubscriptionView,
    meta: {
      title: 'Your Subscription',
      breadcrumb: [
        { name: 'Home', link: 'HomeView' },
        { name: 'Subscription Details' },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

const DEFAULT_TITLE = 'Augur Investor';
router.afterEach((to) => {
  nextTick(() => {
    console.log('title: ', `${to.meta.title} | ${DEFAULT_TITLE}`);
    document.title = to.meta.title ? `${to.meta.title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  });
});

export default router;
