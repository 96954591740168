<template>
  <div id="main">
    <article class="post">
      <header>
        <div class="title">
          <h2>Reset your Password</h2>
          <p>Please enter your email below.</p>
        </div>
      </header>
      <section>
        <Form class="form" v-slot="{ errors }" @submit=resetPassword>
          <div class="row gtr-uniform">
            <div class="col-12">
              <Field id="loginName" type="text" name="email" :rules="isRequired" placeholder="Email" />
              <span class="error">{{ errors.email }}</span>
            </div>
            <div class="col-12">
              <ul class="actions">
                <li><button id="loginButton" class="button" :class="loading && 'disabled'">Reset Password</button></li>
                <li><router-link class="button icon solid fa-chevron-left" :to="{ name: 'HomeView'}" >Return to Login</router-link></li>
              <div class="loading" v-show=loading >
                <i class="icon solid fa-sync-alt fa-spin"></i>
                <span>Please wait...</span>
              </div>
              <div class="loading" v-show=error >
                <i class="icon solid fa-times-circle"></i>
                <span>{{ error }}</span>
              </div>
              <div class="loading" v-show=password >
                <i class="icon solid fa-check-circle"></i>
                <span>A password reset email has been sent.</span>
              </div>
              </ul>
            </div>
          </div>
        </Form>
      </section>
    </article>
  </div>
</template>

<script>
import { Field, Form } from 'vee-validate';

export default {
  name: 'ForgotPasswordView',
  components: {
    Field,
    Form,
  },
  data() {
    return {
      loading: false,
      error: false,
      password: false,
    };
  },
  methods: {
    async resetPassword({ email }) {
      this.loading = true;
      this.error = false;
      this.password = false;
      return this.$store.dispatch('resetPassword', {
        email,
      })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.password = true;
        });
    },
    isRequired(value) {
      return value ? true : 'This field is required';
    },
  },
  watch: {
    $route() {
      this.$store.commit('clearTemporaryAvatar');
    },
  },
};
</script>
