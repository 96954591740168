<template>
    <article class="post">
      <header>
        <div class="title">
          <h2><router-link
              :to="{ name: 'PropertyView',
              params: { investorId: investor.id, propertyId: property.id }}">
                {{ property.address }}
            </router-link></h2>
          <p>{{property.city}} {{property.state}} {{property.postcode}}</p>
        </div>
        <div v-if="owners.length" class="meta">
          <h4>Owner{{ owners.length > 1 ? 's' : '' }}</h4>
          <div v-for="owner in owners" :key=owner.investor.id >
            <router-link
              class="author"
              :to="{ name: 'InvestorView',
              params: { investorId: owner.investor.id }}">
              <span class="name">{{ owner.investor.name }}</span><img :src=owner.investor.avatar.image :alt=owner.investor.name />
            </router-link>
          </div>
        </div>
      </header>
      <router-link
        class="image featured"
        :to="{ name: 'PropertyView',
        params: { investorId: investor.id, propertyId: property.id }}">
      <img :src=property.avatar.image :alt=property.address /></router-link>
      <footer>
        <ul class="actions">
          <li><router-link
            class="button fit"
            :to="{ name: 'PropertyView',
            params: { propertyId: property.id }}">View Property</router-link></li>
          <li><router-link
            class="button fit"
            :to="{ name: 'PropertyEditView',
            params: { propertyId: property.id }}">
              Edit Property
          </router-link></li>
        </ul>
        <ul class="stats">
          <li>${{ (parseInt(property.finance.purchase.land, 10) + parseInt(property.finance.purchase.building, 10)).toLocaleString() }}</li>
          <li><i class="icon solid fa-bed" />{{ property.bedrooms }}</li>
          <li><i class="icon solid fa-bath" />{{ property.bathrooms }}</li>
          <li><i class="icon solid fa-car" />{{ property.carSpaces }}</li>
        </ul>
      </footer>
    </article>
</template>

<script>

export default {
  name: 'Property',
  props: {
    property: Object,
  },
  computed: {
    investor() {
      return this.$store.getters.getInvestor(this.$route.params.investorId);
    },
    owners() {
      return this.property.ownership;
    },
  },
  created() {
    if (typeof this.property.ownership === 'undefined') {
      const ownership = this.property.owners.map(
        (ownerId) => {
          const owner = this.$store.getters.getInvestor(ownerId);
          return {
            key: owner.id,
            investor: owner,
            percentage: 100 / this.property.owners.length,
          };
        },
      );
      this.$store.dispatch('editProperty', {
        name: 'ownership',
        value: ownership,
        id: this.property.id,
      });
    }
  },
};
</script>
