<template>
  <p>Hello World</p>
</template>

<script>
import { auth } from '@/firebase';

export default {
  name: 'UserCreate',
  props: ['email', 'password'],
  methods: {
    async resetPassword() {
      return auth.createUserWithEmailAndPassword(this.email, this.password)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          this.loading = false;
          switch (error.code) {
            case 'auth/wrong-password':
              this.error = 'incorrect password';
              break;
            case 'auth/user-not-found':
              this.error = 'incorrect username';
              break;
            case 'auth/too-many-requests':
              this.error = 'account locked';
              break;
            default:
              this.error = 'unknown error';
          }
        });
    },
  },
};
</script>
